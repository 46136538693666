import React from "react";
import PropTypes from "prop-types";
import HotClick from "@web/ui/containers/HotClick";
import {AudioIcon} from "@web/assets/icons";
import ReactDictateButton, {useReadyState, useSupported} from 'react-dictate-button';
import {Tooltip} from "antd";

DictateButton.propTypes = {
  onDictate: PropTypes.func,
  onProgress: PropTypes.func,
};

function DictateButton(props) {
    const [supported, readyState] = useSupported()
    const [state] = useReadyState(readyState)

    const onClick = () => {
      props.onClick && props.onClick();
    }
    const onDictate = (event) => {
      props.onDictate && props.onDictate(event.result.transcript, event);
    }
    const onProgress = (progress) => {
      props.onProgress && props.onProgress(progress);
    }
    return <HotClick>
        <Tooltip title="Use speech" trigger="hover">
          <ReactDictateButton
            grammar="#JSGF V1.0; grammar districts; public <district> = Tuen Mun | Yuen Long;"
            lang="en-US"
            onClick={onClick}
            onDictate={onDictate}
            onProgress={onProgress}
            disabled={!supported}
          >
            <AudioIcon className="muted-icons" />
          </ReactDictateButton>
        </Tooltip>
    </HotClick>
}

export default DictateButton;
