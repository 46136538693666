import React from 'react';
import PropTypes from 'prop-types';
import {Button, Card, Typography} from "antd";
import {DownArrowOutlined, TruckIcon} from "@web/assets/icons";
import MarkdownTimeline from "./MarkdownTimeline";
import dayjs from "dayjs";

TimelineDeliveriesCard.propTypes = {
  delivery: PropTypes.object,
  path: PropTypes.string,
  date: PropTypes.number,
  collapse: PropTypes.bool,
};

function TimelineDeliveriesCard(props) {
  const [collapsed, setCollapsed] =
    React.useState(props.collapse);

  const onClickCollapse = () => {
    setCollapsed(!collapsed);
  }

  if (!props.delivery) {
    return null;
  }

  return <Card
    bordered={false}
    title={<div className="space space-center-y">
      <TruckIcon />&nbsp;
      <label className="font-xl card-header-title-text">
        {dayjs(props.delivery.date || props.date)
        .format("dddd MMMM D[,] h:mm a")}
      </label>
    </div>}
    extra={<Button ghost onClick={onClickCollapse}><DownArrowOutlined /></Button>}
    className="card-rounded card-simple extra-round extra-padding card-body-scroll"
  >
    <section>
      {!!props.delivery.title &&
        <Typography.Title level={5} className="mb-0">{props.delivery.title}</Typography.Title>
      }
      {!collapsed &&
        <div
          className="space space-col space-between"
          style={{height: '80%'}}
        >
          <MarkdownTimeline
            markdown={props.delivery.summary}
            components={{
              p: (props) => {
                return <Typography.Text
                  className="ai-text"
                  style={{fontSize: '24px', lineHeight: '1.4em', marginBottom: '15px'}}
                >{props.children}
                </Typography.Text>
              }
            }}
          />
        </div>
      }
    </section>
  </Card>
}

export default TimelineDeliveriesCard;
