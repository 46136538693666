import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";
import ContactDetailsListItemMeta from "./ContactDetailsListItemMeta";
import RecommendedTodo from "./RecommendedTodo";

/**
 * @typedef {Object} web.client.ContentItemActivity
 * @property {string} activityPath
 * @property {omit<models.Activity.ActivityEvent, "recommendedTodo", "reminder", "update">} event
 * @property {string} title
 * @property {string} goal
 * @property {models.Activity.ActivityEventRecommendedTodo} [recommendedTodo]
 * @property {reminder: models.Activity.ActivityEventReminder} [reminder]
 * @property {number} date
 * @property {string} serviceId
 */


TimelineItemEventsList.propTypes = {
  contextPath: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  date: PropTypes.number.isRequired,
  serviceId: PropTypes.string.isRequired,
  cardTitle: PropTypes.string,
  primary: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
  actions: PropTypes.arrayOf(PropTypes.node),
};

function TimelineItemEventsList(props) {
  const [expanded, setExpanded] = React
  .useState(false);

  React.useEffect(() => {
    setExpanded(props.showDetails);
  }, [props.showDetails]);

  const eventIdList =
    useSelector((state) => {
      return state.Content.activity?.[props.contextPath]?.eventsByItem?.[props.path];
    });

  const onClick = React
  .useCallback((e) => {
    // console.log("TimelineItemEventsList:onClick", expanded);
    e && e.preventDefault();
    e && e.stopPropagation();
    props.onClick &&
    props.onClick(e);
    setExpanded(!expanded);
    // return false;
  }, [expanded]);

  if (!eventIdList?.length) {
    return props.children;
  }

  return <React.Fragment>
    {eventIdList.map((eventId, index) => {
      return <ContactDetailsListItemMeta
        key={eventId}
        path={props.path}
        eventId={eventId}
        displayName={props.displayName}
        name={props.name}
        email={props.email}
        contactIcon={props.contactIcon}
        primary={props.primary}
        onClick={onClick}
      />
    })}
    <div style={{marginLeft: '60px'}}>
      <RecommendedTodo
        key={eventIdList[0]}
        eventId={eventIdList[0]}
        defaultExpanded={props.primary}
        expand={props.primary ? true : expanded}
        primary={props.primary}
      />
    </div>
    {expanded &&
      <div style={{marginLeft: '60px'}}>{props.children}</div>}
  </React.Fragment>;
}

export default TimelineItemEventsList;
