import {Device, Call} from "@twilio/voice-sdk";
import {api} from "./api";

/** @typedef {('error' | 'incoming' | 'connecting' | 'connected' | 'disconnecting' | 'disconnected' | 'registered' | 'registering' | 'tokenWillExpire' | 'unregistered')} TwilioEvent */
const voice = {};
// /**
//  * @summary Twilio Voice
//  * @returns {{register: function, getDevice: function, connect: function}}
//  * @constructor
//  */
const TwilioVoice = () => {
  /**
   * @summary Get a Twilio device
   * @param {Device.Options} options
   * @returns {Promise<{device:Device, identity:string}>}
   */
  const getDevice = async (options) => {
    if (voice.device) {
      return Promise.resolve({device: voice.device});
    }
    return api.call('twilio-twiml-token', {})
    .then(({data}) => {
      const {token, identity, error} = data;
      if (error) return {error};

      voice.device = new Device(token, {
        appName: 'TheScribe',
        debug: false,
        edge: 'ashburn',
        enableImprovedSignalingErrorPrecision: true,
        allowIncomingWhileBusy: true,
        // logLevel: 1,
        sounds: {},
        ...(options || null),
      });

      return {
        device: voice.device,
        identity,
      };
    })
    .catch((error) => {
      console.log('twilio-twiml-token error', error);
      return {error};
    });
  };

  const registerDevice = async (options) => {
    return voice.device.register()
    .then(() => {
      return {device: voice.device}
    })
    .catch(err => {
      console.log("register error", err.message);
    });
  }
  /**
   * @async
   * @summary Connect a call
   * @param {Record<string, string>} params
   * @returns {Promise<Call>}
   */
  const connect = async (params) => {
    console.log("connect:", params);
    return voice.device.connect({params});
  }

  return {
    /**
     * @summary Register Twilio device
     * @returns {{device: Device}}
     */
    register: registerDevice,
    getDevice,
    connect,
  };
}

export default TwilioVoice;
