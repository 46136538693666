import React from "react";
import PropTypes from "prop-types";
// import {UploadResult} from "firebase/storage";
import {AppContext, ChatContext, ScribeContext, WindowContext} from "@web/hooks/context";
import ChatInputManager from "@web/ui/containers/ChatInputManager";
import {Collapse, Alert, Button} from "antd";
import ChatThread from "./ChatThread";
import ChatInputTextarea from "./ChatInputTextarea";
import {DownArrowOutlined} from "@web/assets/icons";
// import ChatContextList from "./ChatContextList";
import ChatFeedback from "./ChatFeedback";
import useChatStream from "@web/hooks/useChatStream";
import QuickPrompts from "./QuickPrompts";
import RecentPromptsWrapper from "@web/ui/containers/RecentPromptsWrapper";
import ChatItemSuggestionsWrapper from "@web/ui/containers/ChatItemSuggestionsWrapper";
// import ScribeWorkflowPrompt from "./ScribeWorkflowPrompt";
// import appActions from "@web/redux/app/actions";
// import {useDispatch, useSelector} from "react-redux";
import ChatActionsWrapper from "@web/ui/containers/ChatActionsWrapper";
import uniq from "lodash/uniq";
import {useSelector} from "react-redux";
// import ChatMediaManager from "./ChatMediaManager";

ScribeWorkflow.propTypes = {
  agentId: PropTypes.string,
  collectionPath: PropTypes.string.isRequired,
  input: PropTypes.object,
  actions: PropTypes.arrayOf(PropTypes.node),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ghost: PropTypes.bool,
  showThread: PropTypes.bool,
  placeholder: PropTypes.string,
  collapsed: PropTypes.bool,
  primary: PropTypes.bool,
  activeItemPaths: PropTypes.arrayOf(PropTypes.string),
  onSend: PropTypes.func,
  onFocus: PropTypes.func,
  onClose: PropTypes.func,
};

function ScribeWorkflow({width = '100%', extra = [], agentId, collapsed, hideActionTags, ghost, input = {}, ...props}) {
  const [, user] = React.useContext(AppContext);
  const [,, height] = React.useContext(WindowContext);
  const [focus, setFocus] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [chatTopic, setChatTopic] = React.useState(null);
  const [isActive, setIsActive] = React.useState(false);
  const [pushPrompt, setPushPrompt] = React.useState(null);
  const [activeItemPaths, setActiveItemPaths] = React.useState([...props.activeItemPaths]);
  const [chatWidgetItemPaths, setChatWidgetItemPaths] = React.useState([]);
  const [files, setFiles] = React.useState(null);
  const [, sendRequest, streamError] = useChatStream(agentId, true);
  const [history, setHistory] = React.useState(3);

  const [
    activeChatPanels,
    setActiveChatPanels,
  ] = React
  .useState(["chat-history", "chat-actions", "chat-input", "chat-answer"]);

  const activeUserTaskPath =
    useSelector((state) =>
      state.AI.userTask?.path);

  const collectionPath = activeUserTaskPath ? // Task path always overrides collection paths
    activeUserTaskPath + `/${agentId}` :
    props.collectionPath;

  if (!collectionPath) {
    return <Alert message="Collection path is missing or invalid" type="warning" />;
  }

  React.useEffect(() => {
    setActiveItemPaths(props.activeItemPaths);
  }, [props.activeItemPaths]);

  const addChatWidgetItemPath = React
  .useCallback((...widgetItemPaths) => {
    console.log("addChatWidgetItemPaths", {widgetItemPaths});
    setChatWidgetItemPaths(uniq([
      ...chatWidgetItemPaths,
      ...widgetItemPaths,
    ]));
  }, [chatWidgetItemPaths]);

  const removeChatWidgetItemPath = React
  .useCallback((widgetItemPath) => {
    console.log("removeChatWidgetItemPath", {widgetItemPath});
    setChatWidgetItemPaths(
      chatWidgetItemPaths.filter((path) => path !== widgetItemPath));
  }, [chatWidgetItemPaths]);

  const onFocus = React
  .useCallback((editor, isFocused) => {
    setFocus(isFocused);
    isFocused &&
    setIsActive(true);
    props.onFocus &&
    props.onFocus(isFocused);
  }, [props.onFocus]);

  /**
   * @function onSend
   * @param {string} text
   * @param {boolean} isRetry
   * @type {(function(*, *): void)|*}
   */
  const onSend = React
  .useCallback((text, isRetry) => {
    props.onSend ?
      props.onSend(text, isRetry) :
      sendRequest(
        text,
        [
          ...activeItemPaths,
          ...chatWidgetItemPaths,
        ],
        history,
        collectionPath,
      );
    setChatTopic(text ? {path: 'chat', title: text} : null);
  }, [activeItemPaths, chatWidgetItemPaths, history, collectionPath]);

  /**
   * @function onMediaReady
   * @type {(function(...filePaths:string): void)|*}
   */
  const onMediaReady = React
  .useCallback((...filePaths) => {
    setFiles(filePaths);
    addChatWidgetItemPath(...filePaths);
  }, []);

  return <div
    className={`scribe-workflow-wrapper${loading ? ' loading' : '' }`}
    style={{width: width, maxWidth: 'auto', boxSizing: 'border-box', padding: '0 0 0'}}
  >
    <ScribeContext.Provider
      value={{scribeId: user?.uid}}
    >
      <ChatContext.Provider
        value={[agentId, collectionPath, addChatWidgetItemPath, removeChatWidgetItemPath]}>
        <Collapse
          className="glass"
          bordered={false}
          style={{backgroundColor: "transparent"}}
          activeKey={activeChatPanels}
          ghost={ghost}
          expandIconPosition="end"
          expandIcon={(props) =>
            <DownArrowOutlined {...props} size={22} />
          }
          onChange={(activeKeys) =>
            setActiveChatPanels(activeKeys)
          }
        >
          <Collapse.Panel
            header={null} // History
            key="chat-history"
            collapsible
            showArrow
            className="collapse-panel-no-header"
          >
            <ChatThread
              collectionPath={collectionPath}
              style={{height: 'auto', maxHeight: height - 140}}
              lazy
              orientation="bottom"
              empty={
              <div className="space space-col">
                <ChatItemSuggestionsWrapper
                  title="Suggestions"
                  agentId={agentId}
                  onClick={onSend}
                  component={QuickPrompts}
                  pageSize={3}
                  limit={4}
                />
              </div>
              }
            />
          </Collapse.Panel>
          <Collapse.Panel
            header={null} // "Answer"
            key="chat-answer"
            collapsible
            showArrow
            className="collapse-panel-no-header"
          >
            <ChatFeedback
              agentId={agentId}
              promptText={chatTopic?.title}
              onAddContextPath={addChatWidgetItemPath}
              onRemoveContextPath={removeChatWidgetItemPath}
            />
            {!!streamError &&
              <div style={{marginTop: '6px'}}>
                <Alert
                  message={streamError.message}
                  action={
                  <Button
                    size="small"
                    ghost
                    onClick={() =>
                      onSend(chatTopic.title, true)
                  }>
                    <span className="green-color">Retry</span>
                  </Button>}
                  type="error"
                />
            </div>}
          </Collapse.Panel>
          <Collapse.Panel
            header={null} // "Actions"
            key="chat-actions"
            collapsible
            showArrow
            className="collapse-panel-no-item-separator collapse-panel-no-header"
          >
            {focus &&
              !chatTopic?.title &&
              <ChatActionsWrapper
                title="Quick Actions"
                pageSize={2}
                limit={2}
                onClick={onSend}
                component={QuickPrompts}
                className="green-color"
              />
            }
          </Collapse.Panel>
          <Collapse.Panel
            header="Chat"
            key="chat-input"
            collapsible
            showArrow
            className="collapse-panel-no-header"
          >
            {/*{focus &&*/}
            {/*  <RecentPromptsWrapper*/}
            {/*    title="Recent"*/}
            {/*    agentId={agentId}*/}
            {/*    collectionPath={collectionPath}*/}
            {/*    pageSize={2}*/}
            {/*    limit={2}*/}
            {/*    onClick={onSend}*/}
            {/*    component={QuickPrompts}*/}
            {/*  />}*/}
              <ChatInputManager
                pushPrompt={pushPrompt}
                onSend={onSend}
                onFocus={onFocus}
                onError={props.onError}
                onMediaReady={onMediaReady}
                placeholder={props.placeholder}
              >
                <ChatInputTextarea dictate primary ai />
              </ChatInputManager>
          </Collapse.Panel>
        </Collapse>
      </ChatContext.Provider>
    </ScribeContext.Provider>
  </div>
}

export default ScribeWorkflow;

