import React from 'react';
import PropTypes from 'prop-types';
import {RightArrowOutlined} from "@web/assets/icons";

InlineItem.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

function InlineItem(props) {
  const [expanded, setExpanded] =
    React.useState(false);

  return expanded ?
    props.children :
    <span
      className="underline green-color icon-btn"
      onClick={() => setExpanded(true)}>
      <RightArrowOutlined size={20} /> {props.name}
    </span>;
}

export default InlineItem;
