import React, {Fragment} from 'react'
import ChatPrompt from './ChatPrompt'
import ChatResponse from './ChatResponse'
import ChatResponseStream from './ChatResponseStream'
import PropTypes from "prop-types";

ChatItem.propTypes = {
  path: PropTypes.string.isRequired,
  chat: PropTypes.object.isRequired,
  stream: PropTypes.object,
  chatIndex: PropTypes.number.isRequired,
};

export default function ChatItem(props) {
  const [collapsed, setCollapsed] = React
  .useState(false);

  const toggleCollapsed = React
  .useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed]);

  if (!props.path) {
    console.log("Path is now required in AI chat", {path: props.path})
  }

  return <Fragment>
    <ChatPrompt
      path={props.path}
      chat={props.chat}
      onClick={toggleCollapsed}
    />
    {!collapsed &&
      !props.stream &&
      <ChatResponse
        chat={props.chat}
        path={props.path}
        chatIndex={props.chatIndex}
      />}
    {!collapsed &&
      props.stream &&
      <ChatResponseStream
        chat={props.chat}
        path={props.path}
        stream={props.stream}
        chatIndex={props.chatIndex}
      />}
    {/*<AssistantSearchResults chatId={chatId} chat={chat} like={chat.like} collectionPath={collectionPath} />*/}
  </Fragment>
}
