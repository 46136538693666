import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {EyeIcon, LayersIcon, LeftArrowOutlined, RightArrowOutlined, TerminalIcon} from "@web/assets/icons";
import StorageFile from "@web/ui/containers/StorageFile";
import {Button, Carousel, Image, Typography} from "antd";
import appActions from "@web/redux/app/actions";
import {useDispatch, useSelector} from "react-redux";
import {WindowContext} from "@web/hooks/context";

DocumentCard.propTypes = {
  file: PropTypes.object,
  path: PropTypes.string,
  size: PropTypes.string,
  onClick: PropTypes.func,
  preview: PropTypes.bool,
  lazy: PropTypes.bool,
};

/**
 * @function DocumentCard
 * @param {object} props
 * @param {models.File.Data} props.file
 * @param {string} props.path
 * @param {("small" | "medium" | "large" | "xl" | "xxl" | "block")} props.size
 * @param {function} props.onClick
 * @param {boolean} props.preview
 * @param {boolean} props.lazy
 * @return {Element}
 * @constructor
 */
function DocumentCard(props) {
  // const dispatch = useDispatch();
  const [isMobile] = useContext(WindowContext);

  // const activePath = useSelector((state) =>
  //   state.App[state.App.activeDetailServiceId]?.props?.path);

  // const setActiveDetail =
  //   React.useCallback((serviceId, props) => {
  //   dispatch(appActions.setActiveDetail(
  //     serviceId,
  //     props,
  //     true),
  //   );
  // }, [dispatch]);

  const [collapse, setCollapse] =
    React.useState(!!props.lazy);

  if (!props.file) {
    return null;
  }

  if (!props.file.previews?.length) {
    if (props.file.mimeType === "application/pdf" || props.file.pdf?.storageURI) {
      const storageURI = props.file.pdf?.storageURI || props.file.storageURI;
      return <div style={{background: 'rgba(255, 255, 255, 0.9)'}}>
        <StorageFile
        storageURI={storageURI}
        key={storageURI}
      >
        <Image
          className="img-document"
          onClick={props.onClick}
          wrapperStyle={{border: "solid 3px transparent"}}
          preview={!props.onClick && props.preview}
          key={storageURI}
          // height={"auto"}
          // placeholder={
          //   <Image
          //     preview={false}
          //     src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png?x-oss-process=image/blur,r_50,s_50/quality,q_1/resize,m_mfit,h_200,w_200"
          //     width={1200}
          //     height={1000}
          //   />
          // }
        />
      </StorageFile>
      </div>
    }
    return <Typography.Title
      level={4}
      type="secondary"
      onClick={props.onClick}
    >
      {props.file?.mimeType?.split("/")?.[1]?.split(".")?.pop()}
    </Typography.Title>;
  }

  // console.log('>>>>>props.file:', props.file);

  return <div className="thumbnail-slider-wrapper relative">
    {/*<div className="space space-center-y space-between" style={{paddingBottom: '10px', height: '45px'}}>*/}
    {/*  <div>*/}
    {/*    {props.lazy &&*/}
    {/*      <Button*/}
    {/*        ghost*/}
    {/*        onClick={() => setCollapse(!collapse)}*/}
    {/*        className="icon-btn icon-right"*/}
    {/*        style={{position: 'relative', left: 70}}*/}
    {/*      >*/}
    {/*        <span className="bolded">{collapse ? "Show" : "Hide"} File</span>*/}
    {/*        <EyeIcon size={20}/>*/}
    {/*      </Button>*/}
    {/*    }*/}
    {/*  </div>*/}
    {/*  {activePath !== props.path ?*/}
    {/*    <Button*/}
    {/*    className="green-color icon-btn" shape="round" style={{marginRight: 10}}*/}
    {/*    onClick={() =>*/}
    {/*      setActiveDetail(sources.services.files.key, {path: props.path})}*/}
    {/*  >*/}
    {/*    <TerminalIcon size={18}/> Explore*/}
    {/*  </Button> :*/}
    {/*  <div/>*/}
    {/*}*/}
    {/*</div>*/}
    {!collapse &&
    <Carousel
      className="ant-carousel-nav-top"
      touchMove={false}
      key={props.file.path}
      arrows={true}
      dots={false}
      lazyLoad="ondemand"
      infinite={false}
      draggable={!!isMobile}
      waitForAnimate={true}
      accessibility={false}
      adaptiveHeight={false}
      focusOnSelect={false}
      variableWidth={false}
      prevArrow={<Button ghost size="small" className="icon-right"><LeftArrowOutlined className="green-icon"/></Button>}
      nextArrow={<Button ghost size="small" className="icon-right"><RightArrowOutlined
        className="green-icon"/></Button>}
    >
      {props.file.previews.map(({storageURI}) => {
        // console.log('>>>>>preview:storageURI:', storageURI);
        return <StorageFile
          storageURI={storageURI}
          key={storageURI}
        >
          <Image
            className="img-document"
            onClick={props.onClick}
            // wrapperStyle={{border: "solid 3px transparent"}}
            preview={!props.onClick && props.preview}
            key={storageURI}
            // height={"auto"}
            // placeholder={
            //   <Image
            //     preview={false}
            //     src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png?x-oss-process=image/blur,r_50,s_50/quality,q_1/resize,m_mfit,h_200,w_200"
            //     width={1200}
            //     height={1000}
            //   />
            // }
          />
        </StorageFile>
      })}
    </Carousel>}
    {/*<h6 className="space space-center-y">*/}
    {/*  <LayersIcon /><span className="ai-text">{(props.file?.form || props.file?.other)?.name}</span>*/}
    {/*</h6>*/}
  </div>;
}

export default DocumentCard;
