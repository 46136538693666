import actions from "./actions";
import uniq from "lodash/uniq.js";

const initState = {
  callMessage: "",
  activeCalls: [],
  registering: false,
  registered: false,
  muted: false,
  record: false,
  deviceError: null,
  twilioError: null,
  warning: [],
};

/**
 * @summary Reducer for activity
 * @param {object} state
 * @param {string} type
 * @param {object} payload
 * @return {*}
 */
export default function reducer(state = initState, {type, payload}) {
  switch (type) {
    case actions.VOICE_DEVICE_REGISTERING:
      console.log("VOICE_DEVICE_REGISTERING");
      return {
        ...state,
        registering: true,
        deviceError: null,
        statusTs: Date.now(),
      };
    case actions.VOICE_DEVICE_REGISTERED:
      console.log("VOICE_DEVICE_REGISTERED");
      return {
        ...state,
        registered: true,
        registering: false,
        deviceError: null,
        statusTs: Date.now(),
      };
    case actions.VOICE_DEVICE_UNREGISTERED:
      console.log("VOICE_DEVICE_UNREGISTERED");
      return {
        ...initState,
        // activeCalls: [],
        // registered: false,
        // registering: false,
        statusTs: Date.now(),
      };
    case actions.VOICE_CALL_INCOMING:
      console.log("VOICE_CALL_INCOMING", payload);
      if (!payload.callSid) {
        throw new Error("VOICE_CALL_INCOMING:missing-callSid");
      }

      return {
        ...state,
        activeCalls: uniq([
          ...state.activeCalls || [],
          payload.callSid,
        ]),
        [payload.callSid]: {
          ...payload,
        },
        callMessage: "",
        deviceError: null,
        statusTs: Date.now(),
      };
    case actions.VOICE_CALL_INCOMING_ACCEPTED:
      console.log("VOICE_CALL_INCOMING_ACCEPTED", payload);
      if (!payload.callSid) {
        throw new Error("VOICE_CALL_INCOMING:missing-callSid");
      }

      return {
        ...state,
        activeCalls: uniq([
          ...state.activeCalls || [],
          payload.callSid,
        ].filter(Boolean)),
        [payload.callSid]: {
          ...payload,
        },
        callMessage: "",
        deviceError: null,
        statusTs: Date.now(),
      };
    case actions.VOICE_CALL_CANCELLED: {
      console.log("VOICE_CALL_CANCELLED", payload);
      return {
        ...state,
        activeCalls: state.activeCalls.filter((callSid) => callSid !== payload.callSid),
        [payload.callSid]: null,
        callMessage: "Cancelled",
        statusTs: Date.now(),
      };
    }
    case actions.VOICE_CALL_IGNORED: {
      console.log("VOICE_CALL_IGNORED", payload);
      state.call = null;
      return {
        ...state,
        activeCalls: state.activeCalls.filter((callSid) => callSid !== payload.callSid),
        [payload.callSid]: null,
        callMessage: "Ignored",
        statusTs: Date.now(),
      };
    }
    case actions.VOICE_CALL_MUTED: {
      console.log("VOICE_CALL_MUTED", payload);
      return {
        ...state,
        muted: payload.e,
        statusTs: Date.now(),
      };
    }
    // case actions.VOICE_CALL_ACCEPTED: {
    //   console.log("VOICE_CALL_ACCEPTED", payload);
    //   return {
    //     ...state,
    //     [payload.callSid]: {
    //       ...state[payload.callSid],
    //       ...payload,
    //     },
    //     callMessage: "",
    //     statusTs: Date.now(),
    //   };
    // }
    case actions.VOICE_CALL_REJECTED: {
      console.log("VOICE_CALL_REJECTED", payload);
      return {
        ...state,
        activeCalls: state.activeCalls.filter((callSid) => callSid !== payload.callSid),
        [payload.callSid]: null,
        callMessage: "Rejected",
        statusTs: Date.now(),
      };
    }
    case actions.VOICE_CALL_RINGING: {
      console.log("VOICE_CALL_RINGING", payload);
      return {
        ...state,
        callMessage: "Ringing...",
        hasEarlyMedia: payload.e,
        statusTs: Date.now(),
      };
    }
    case actions.VOICE_CALL_CONNECTING: {
      console.log("VOICE_CALL_CONNECTING", payload);
      return {
        ...state,
        [payload.callSid]: {
          ...state[payload.callSid],
          ...payload,
        },
        callMessage: "Connecting...",
        statusTs: Date.now(),
      };
    }
    // case actions.VOICE_CALL_CONNECTED: {
    //   console.log("VOICE_CALL_CONNECTED", payload);
    //   const [call] = payload?.args;
    //   return {
    //     ...state,
    //     connecting: false,
    //     call,
    //     callMessage: "Connected",
    //     // callError: null,
    //     deviceError: null,
    //     statusTs: Date.now(),
    //   };
    // }
    case actions.VOICE_CALL_DISCONNECTING: {
      console.log("VOICE_CALL_DISCONNECTING", payload);
      return {
        ...state,
        disconnecting: true,
        callMessage: "Disconnecting...",
        statusTs: Date.now(),
      };
    }
    // case actions.VOICE_CALL_DISCONNECT: {
    //   console.log("VOICE_CALL_DISCONNECT", payload);
    //   state.call = null;
    //   return {
    //     ...state,
    //     disconnecting: false,
    //     callMessage: '',
    //     statusTs: Date.now(),
    //   }
    // }
    case actions.VOICE_CALL_DISCONNECTED: {
      console.log("VOICE_CALL_DISCONNECTED", payload);
      return {
        ...state,
        activeCalls: state.activeCalls.filter((callSid) => callSid !== payload.callSid),
        [payload.callSid]: {
          ...state[payload.callSid] || null,
          ...payload,
        },
        callMessage: "",
        statusTs: Date.now(),
      };
    }
    case actions.VOICE_CALL_RECONNECTING: {
      console.log("VOICE_CALL_RECONNECTING", payload);
      return {
        ...state,
        [payload.callSid]: {
          ...state[payload.callSid],
          ...payload,
          // status: "reconnecting",
        },
        statusTs: Date.now(),
      };
    }
    case actions.VOICE_CALL_RECONNECTED: {
      return {
        ...state,
        twilioError: null,
        statusTs: Date.now(),
      };
    }
    case actions.VOICE_CALL_ERROR: {
      console.log("VOICE_CALL_ERROR", payload);
      return {
        ...state,
        [payload.callSid]: {
          ...state[payload.callSid],
          ...payload,
        },
        statusTs: Date.now(),
      };
    }
    case actions.VOICE_CALL_WARNING:
      console.log("VOICE_CALL_WARNING", payload);
      return {
        ...state,
        [payload.callSid]: {
          ...state[payload.callSid],
          ...payload,
        },
        statusTs: Date.now(),
      };
    case actions.VOICE_CALL_WARNING_CLEARED:
      console.log("VOICE_CALL_WARNING_CLEARED", payload);
      return {
        ...state,
        [payload.callSid]: {
          ...state[payload.callSid],
          warning: "",
          ...payload,
        },
        statusTs: Date.now(),
      };
    case actions.VOICE_DEVICE_ERROR:
      console.log("VOICE_DEVICE_ERROR", payload);
      const [deviceError] = payload?.args;
      return {
        ...state,
        deviceError,
        statusTs: Date.now(),
      };
    case actions.VOICE_CALL_TOGGLE_RECORD:
      console.log("VOICE_CALL_RECORD", payload);
      return {
        ...state,
        record: !state.record,
        statusTs: Date.now(),
      };
      case actions.VOICE_CALL_CLEARED:
        console.log("VOICE_CALL_CLEARED", payload);
        return {
          ...state,
          activeCalls: state.activeCalls.filter((callSid) => callSid !== payload.callSid),
          [payload.callSid]: null,
          statusTs: Date.now(),
        };
    default:
      console.log("reducer:VOICE_CALL_DEFAULT:", {type, payload});
      return state;
  }
}
