import React from "react";
import PropTypes from "prop-types";
import {Button, Card, Tag, Typography} from "antd";
import StatusActions from "./StatusActions";
import {EmailIconOutlined, PhoneCallIcon} from "@web/assets/icons";

BusinessCard.propTypes = {
  data: PropTypes.object,
  path: PropTypes.string,
};

function BusinessCard(props) {
  if (!props.data) {
    return null;
  }

  return <Card
    title={<h6>Business</h6>}
    className="card-rounded card-simple ant-card-body"
    style={{width: 400, marginTop: '14px'}}
    hoverable
  >
    <div
      style={{position: 'relative'}}
      className="space space-col space-between"
    >
      <Typography.Paragraph className="font-lg bolded">
        {props.data.name}
      </Typography.Paragraph>
      <h6 style={{position: "absolute", bottom: "-20px", left: "0", right: "0", paddingTop: "6px"}}>
        <div className="space space-center-y">
          <Button ghost className="icon-btn icon-left">
            <EmailIconOutlined/>
          </Button>
          <Button ghost className="icon-btn icon-left">
            <PhoneCallIcon/>
          </Button>
        </div>
      </h6>
    </div>
  </Card>
}

export default BusinessCard;
