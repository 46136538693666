import React from 'react'
import {Space} from "antd";
import ContentActions from './ContentActions'
import AiChatAnswer from './AiChatAnswer'
import AiChatAlert from './AiChatAlert'
import PropTypes from "prop-types";

ChatResponse.propTypes = {
  path: PropTypes.string.isRequired,
  chat: PropTypes.object.isRequired,
  chatIndex: PropTypes.number.isRequired,
  // stream: PropTypes.object,
}

export default function ChatResponse(props) {
  return <li className="brainstorm-message left">
    <Space direction="vertical">
      <div className="sub-message-group-wrapper">
        {props.chat.answers?.map((answer, answerIndex) => {
          if (!answer.text && answer.toolCalls) {
            console.log("ChatResponse:answer:toolCalls:skipping:", answer);
            return null;
          }
          return <ContentActions
            path={props.path}
            text={answer.text}
            like={answer.like}
            answerIndex={answerIndex}
            key={answerIndex}
          >
            <AiChatAnswer
              agentId={answer.agentId}
              chatIndex={props.chatIndex}
              answerIndex={answerIndex}
              text={answer.text}
              path={props.path}
              toolCalls={answer.toolCalls}
              toolResults={answer.toolResults}
              charts={answer.charts}
            />
          </ContentActions>;
        })}
      </div>
      {/*{!!confirmation &&*/}
      {/*  <AiChatConfirmation path={path} confirmation={confirmation} />*/}
      {/*}*/}
      {/*{props.chat.progressTs &&*/}
      {/*  (props.chat.progressTs > (props.chat.updatedTs || 0)) &&*/}
      {/*  <article className="sub-message">*/}
      {/*    <div className="space">*/}
      {/*      <Avatar size="large" style={{visibility: "hidden"}} className="chat-avatar ai-avatar" icon={<FeatherIcon className="ai-text"/>}/>*/}
      {/*      <MarkdownTimeline*/}
      {/*        markdown={props.chat.progress}*/}
      {/*        path={props.path}*/}
      {/*        active*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*  </article>*/}
      {/*}*/}
      {props.chat.alert && <AiChatAlert{...props.chat.alert}/>}
      {/*{!answers.length &&*/}
      {/*  <AnswerLoading*/}
      {/*    prompt={chat.prompt}*/}
      {/*  />*/}
      {/*}*/}
      <br/>
      <br/>
    </Space>
  </li>
// const onClickRetry = (e) => {
  //   e.stopPropagation()
  //   updateDocument("ai-chat", chatId, {
  //     "prompt.retry": increment(1),
  //     "response.error": Fields.deleteField(),
      //   }).then(() => console.log("Retrying..."));
      //   return false
      // }
      // const responseKeys = Object.keys(response)


  // {/*// switch(answer.agentId) {*/
  // }
  // {/*// case 'ai-summarize-email-thread':*/
  // }
  // {/*//   return <div className="sub-message-group-wrapper" key={index}>*/
  // }
  // {/*//       <AnswerWrapper*/
  // }
  // {/*//         chatId={chatId}*/
  // }
  // {/*//         prompt={prompt}*/
  // }
  // {/*//         answers={answers}*/
  // }
  // {/*//         collection={collectionPath}*/
  // }
  // {/*//       >*/
  // }
  // {/*//         <AiScribeUserThreads/>*/
  // }
  // {/*//       </AnswerWrapper>*/
  // }
  // {/*//     </div>*/
  // }
  // {/*// case 'ai-agent-matcher':*/
  // }
  // {/*//   return <AnswerWrapper*/
  // }
  // {/*//       chatId={chatId}*/
  // }
  // {/*//       agentId={responseKey}*/
  // }
  // {/*//       prompt={prompt}*/
  // }
  // {/*//       response={response[responseKey]}*/
  // }
  // {/*//       key={index}*/
  // }
  // {/*//       collectionPath={collectionPath}*/
  // }
  // {/*//     >*/
  // }
  // {/*//     <AiAgentMatcherAnswer />*/
  // }
  // {/*//   </AnswerWrapper>*/
  // }
  // {/*// case 'ai-extract-actionable':*/
  // }
  // {/*//   return <div className="sub-message-group-wrapper" key={index}>*/
  // }
  // {/*//     <AnswerWrapper*/
  // }
  // {/*//       chatId={chatId}*/
  // }
  // {/*//       agentId={responseKey}*/
  // }
  // {/*//       prompt={prompt}*/
  // }
  // {/*//       response={response[responseKey]}*/
  // }
  // {/*//       collectionPath={collectionPath}*/
  // }
  // {/*//     >*/
  // }
  // {/*//       <AiExtractActionableAnswer/>*/
  // }
  // {/*//     </AnswerWrapper>*/
  // }
  // {/*//   </div>*/
  // }
  // {/*// default:*/
  // }
  // {/*<AnswerWrapper*/
  // }
  // {/*  path={path}*/
  // }
  // {/*  // agentId={responseKey}*/
  // }
  // {/*  prompt={prompt}*/
  // }
  // {/*  answer={response[responseKey]}*/
  // }
  // {/*  collectionPath={collectionPath}*/
  // }
  // {/*>*/
  // }
  // {/*  <AiChatAnswer/>*/
  // }
  // {/*</AnswerWrapper>*/
  // }
  // {/*{!responseKeys.length &&*/
  // }
  // {/*  !confirmation &&*/
  // }
  // {/*  !chat.progress &&*/
  // }
  // {/*  !chat.alert &&*/
  // }
  // {/*  <AnswerLoading prompt={prompt} />}*/
  // }
// </Space>
// </li>
}
