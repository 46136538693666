import React from 'react';
import PropTypes from 'prop-types';
import {Button, Divider, List, Tag, Typography} from "antd";
import {AppContext, SourcesContext, WindowContext} from "@web/hooks/context";
import {UpRightArrowOutlined} from "@web/assets/icons";
import useChatStream from "@web/hooks/useChatStream";
import {useSelector} from "react-redux";

Progress.propTypes = {

};

function Progress() {
  const agentId = "ai-progress-assistant";

  const sources = React
  .useContext(SourcesContext);

  const [, user] = React
  .useContext(AppContext);

  const [isMobile, width, height] = React
  .useContext(WindowContext);

  const activeServiceId =
    useSelector((state) =>
      state.App.activeServiceId);

  const [
    answers,
    sendRequest,
    streamError,
  ] = useChatStream(agentId, false);

  React
  .useEffect(() => {
    if (activeServiceId !== "act") {
      return;
    }

    const collectionPath =
      sources.services[activeServiceId]
      ?.chat
      ?.getCollectionPath(
        user,
        "ai-assistant", // We want to write to assistant collection so that the assistant is aware of the progress agent's interactions
      );
    console.log('Progress:useEffect:sendRequest:collectionPath:', collectionPath);
    // sendRequest(`${now()}`, [], 3, collectionPath);
  }, [activeServiceId]);

  return <section
    className="space space-col middle"
    style={{position: "relative", maxWidth: '1200px', height: height - 100}}
  >
    <div style={{width: '100%'}}>
      <div className="space space-between space-center-y">
        <div className="space space-center-y">
          {/*<Button*/}
          {/*  ghost*/}
          {/*  className="icon-btn icon-left"*/}
          {/*  onClick={props.onClose}*/}
          {/*>*/}
          {/*  <Icon name="XIcon" />*/}
          {/*</Button>*/}
          <Typography.Title level={4} className="ai-text">
            What would you like to do next?
          </Typography.Title>
        </div>
        {/*<StatusActions*/}
        {/*  itemId={props.path.split("/").pop()}*/}
        {/*  actions={[]}*/}
        {/*  initialStatus={props.contact.status}*/}
        {/*  path={props.path}*/}
        {/*  extra={*/}
        {/*  <ReactionButton*/}
        {/*    ghost*/}
        {/*    size="small"*/}
        {/*    afterIcon={*/}
        {/*    <LikeIcon*/}
        {/*      size={20}*/}
        {/*      className="green-icon"*/}
        {/*    />}*/}
        {/*    beforeIcon={*/}
        {/*      <LikeIcon size={20} />*/}
        {/*    }*/}
        {/*    reaction="like"*/}
        {/*    itemPath={props.path}*/}
        {/*    value={props.contact.like}*/}
        {/*  />}*/}
        {/*/>*/}
      </div>
      <div>
        <Divider/>
        <List
          style={{width: "100%", margin: '0 auto'}}
          className="list-item-no-divider"
          loading={false}
          dataSource={[
            {tag: "quickies", rank: 1, color: 'green'},
            {tag: "follow-ups", rank: 2, color: 'orange'},
            {tag: "reminders", rank: 3, color: 'yellow'},
          ]}
          renderItem={({tag, rank, color}) =>
            <List.Item key={tag}>
              <div className="fat-chip space space-center-y space-between">
                <Tag color={color} style={{borderRadius: 20, marginLeft: '-2px', borderColor: 'transparent'}} draggable={true}>{tag}</Tag>
                <Button ghost>
                  <UpRightArrowOutlined className="green-icon" />
                </Button>
              </div>
            </List.Item>}
          locale={{emptyText: "No relevant activity"}}
        />
      </div>
    </div>
  </section>
}

export default Progress;
