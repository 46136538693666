import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";
import {AppContext, SourcesContext} from "@web/hooks/context";

ChatActionsWrapper.propTypes = {
  component: PropTypes.elementType.isRequired,
  pageSize: PropTypes.number,
  limit: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  title: PropTypes.string,
  className: PropTypes.string,
};

function ChatActionsWrapper(props) {
  const {solutions, services} =
    React.useContext(SourcesContext);

  const [, user] =
    React.useContext(AppContext);

  const activeSolutionId =
    useSelector((state) =>
      state.App.activeSolutionId);

  const activeServiceId =
    useSelector((state) =>
      state.App.activeDetailServiceId || state.App.activeServiceId);

  const activeItem =
    useSelector((state) =>
      state.App[state.App.activeDetailServiceId]);

  const [prompts, setPrompts] =
    React.useState([]);

  React.useEffect(() => {
    !!activeSolutionId &&
    !!solutions[activeSolutionId]?.chat?.getActions &&
    solutions[activeSolutionId]?.chat?.getActions(user)
      .then((solutionPrompts) => {
        console.log("ChatActionsWrapper:useEffect:solutionPrompts:", solutionPrompts);
        ((activeServiceId &&
        activeItem?.props &&
        activeSolutionId === services[activeServiceId]?.solutionId) &&
        !!services[activeServiceId]?.chat?.getActions &&
        services[activeServiceId]?.chat?.getActions(user, activeItem.props)
        .then((servicePrompts) => {
          console.log("ChatActionsWrapper:useEffect:servicePrompts:", servicePrompts);
          !servicePrompts ?
            solutionPrompts?.length ?
              setPrompts(solutionPrompts) :
              setPrompts([]) :
            setPrompts(servicePrompts);
        })) ||
        setPrompts(solutionPrompts?.length ? solutionPrompts : []);
      });
  }, [activeSolutionId, activeServiceId, activeItem?.props]);

  if (!prompts?.length) {
    return null;
  }

  return React.createElement(props.component, {
    prompts,
    pageSize: props.pageSize,
    onClick: props.onClick,
    title: props.title,
    className: props.className,
    agentId: services[activeServiceId]?.chat?.agentId,
  })
}

export default ChatActionsWrapper;
