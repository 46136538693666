import React from "react";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import FileCard from "./FileCard";
import {AttachmentIcon} from "@web/assets/icons";

Attachment.propTypes = {
  brief: PropTypes.string,
  mimeType: PropTypes.string,
  originId: PropTypes.string,
  originFilename: PropTypes.string,
  previews: PropTypes.array,
  size: PropTypes.number,
  filePath: PropTypes.string,
  thumbnail: PropTypes.bool,
};

function Attachment(props) {
  const file =
    useSelector(
      (state) => {
        return props.filePath &&
          state.Content.data?.[props.filePath]
      });

  // if (!props.previews && !file?.previews) {
  //   return <span className="fat-chip space space-center-y">
  //     <AttachmentIcon size={14} />
  //     {props.mimeType.split("/").pop()}
  //   </span>;
  // }
  //
  //

  return <FileCard
    file={
    file || {
      previews: props.previews,
      mimeType: props.mimeType,
      originId: props.originId,
      originFilename: props.originFilename,
      size: props.size,
      brief: props.brief,
    }}
    direction="horizontal"
    size={props.thumbnail ? "small" : "large"}
  />
}

export default Attachment;
