import React from 'react';
import PropTypes from 'prop-types';
import {List} from "antd";
import TimelinePaymentsCard from "../TimelinePaymentsCard";
import TimelineDeliveriesCard from "../TimelineDeliveriesCard";
import TimelineCalendarsCard from "../TimelineCalendarsCard";
import {useSelector} from "react-redux";
import TimelineEmail from "../TimelineEmail";
import TimelineItemEventsList from "../TimelineItemEventsList";
import FileCard from "../FileCard";
import FileData from "@web/ui/containers/FileData";
import ContentData from "@web/ui/containers/ContentData";

DetailItem.propTypes = {
  contextPath: PropTypes.string,
  path: PropTypes.string,
  date: PropTypes.number,
  primary: PropTypes.bool,
  onClick: PropTypes.func,
  collapsed: PropTypes.bool,
};

function DetailItem(props) {
  const activeServiceId =
    useSelector((state) =>
      state.App.activeDetailServiceId);

  if (!props.path) {
    return null;
  }

  const getComponent = () => {
    const serviceId =
      props.path.split("/")[2];

    switch (serviceId) {
      case "messages":
      case "threads": {
        return <TimelineItemEventsList
          key="messages-timeline"
          contextPath={props.contextPath}
          path={props.path}
          date={props.date}
          serviceId="messages"
          cardTitle="Activity"
          primary={props.primary}
          onClick={props.onClick}
        >
          <TimelineEmail
            path={props.path}
            date={props.date}
            hideSubject
            showAttachmentCount={true}
          />
        </TimelineItemEventsList>
      }
      case "files": {
        return <TimelineItemEventsList
          key="files-timeline"
          contextPath={props.contextPath}
          path={props.path}
          date={props.date}
          serviceId="files"
          cardTitle="Activity"
          primary={props.primary}
          onClick={props.onClick}
        >
          <FileData
            path={props.path}
            grid
            bordered={false}
            component={FileCard}
          />
        </TimelineItemEventsList>
      }
      case "payments": {
        return <List.Item key={serviceId}>
          <div
            className="space space-col"
            style={{marginLeft: activeServiceId !== "payments" ? "60px" : 0}}
          >
            <ContentData
              path={props.path}
              dataField="payment"
              date={props.date}
              primary={props.primary}
              collapse={props.index > 0 || activeServiceId !== "payments"}
              component={TimelinePaymentsCard}
            />
          </div>
        </List.Item>
      }
      case "deliveries": {
        return <List.Item key={serviceId}>
          <div
            className="space space-col"
            style={{marginLeft: activeServiceId !== "deliveries" ? "60px" : 0}}
          >
            <ContentData
              path={props.path}
              dataField="delivery"
              date={props.date}
              primary={props.primary}
              collapse={props.index > 0 || activeServiceId !== "deliveries"}
              component={TimelineDeliveriesCard}
            />
          </div>
        </List.Item>
      }
      case "calendars": {
        return <List.Item key={serviceId}>
          <div className="space space-col">
            <TimelineCalendarsCard
              key={props.path}
              path={props.path}
              date={props.date}
              primary={props.primary}
            />
          </div>
        </List.Item>
      }
    }
  }

  return <div className="detail-timeline-day space space-col">
    {getComponent()}
  </div>
}

export default DetailItem;
