import React from 'react';
import PropTypes from 'prop-types';
import {Space} from "antd";
import Attachment from "./Attachment";

AttachmentsStrip.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
  thumbnail: PropTypes.bool,
};

function AttachmentsStrip(props) {
  if (!props.list?.length) {
    return null;
  }

  return <Space
    style={props.style}
  >
    {props.list
    .filter(Boolean)
    .map((attachment) =>
      <Attachment
        key={attachment.filePath || attachment.originId}
        brief={attachment.brief}
        mimeType={attachment.mimeType}
        originId={attachment.originId}
        originFilename={attachment.originFilename}
        previews={attachment.previews}
        size={attachment.size}
        thumbnail={props.thumbnail}
      />)}
  </Space>
}

export default AttachmentsStrip;
