import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {RightArrowOutlined} from "@web/assets/icons";
import ListItemActive from "./ListItemActive";
import MarkdownTimeline from "./MarkdownTimeline";
import {Button, List} from "antd";
import appActions from "@web/redux/app/actions";
import {useDispatch, useSelector} from "react-redux";
import Day from "./Day";

MarkdownListItem.propTypes = {
  dataKey: PropTypes.string,
  date: PropTypes.string,
  path: PropTypes.string,
};

function MarkdownListItem(props) {
  const dispatch = useDispatch();
  const data = useSelector(
    (state) =>
      state.Content.data[props.path]);

  const setActiveDetail =
    useCallback((serviceId, props) =>
        dispatch(appActions
        .setActiveDetail(serviceId, props, true)),
      [dispatch]);

  return <List.Item
    key={props.path}
    style={{position: 'relative'}}
    onClick={() =>
      setActiveDetail(
        props.path.split("/")[2], {
          [props.dataKey]: data,
          path: props.path,
        })
    }>
    <ListItemActive
      path={props.path}
    />
    <List.Item.Meta
      description={<MarkdownTimeline
        markdown={data?.summary}
        className="ai-text"
      />}
      title={
      <div className="space space-center-y">
        <span className="bolded">
          {data?.memberUserIds?.length &&
            data.members[data?.memberUserIds?.[0]].displayName}
          {data?.memberUserIds?.length > 1 &&
            ` +${data?.memberUserIds?.length - 1}`}
        </span>
        <span className="muted font-sm">
          <Day ts={data?.updatedTs} />
        </span>
      </div>
    }>

    </List.Item.Meta>
  </List.Item>;
}

export default MarkdownListItem;
