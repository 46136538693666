import {useState} from "react";
import {getIdTokenResult, User} from "firebase/auth";
import {useAuthState} from "react-firebase-hooks/auth";

/** @typedef {[]} useAuthOvenData
 * @property {User} 0 - The user
 * @property {Object} 1 - The claims
 * @property {boolean} 2 - The loadingUser
 * @property {Error} 3 - The errorLoadingUser
 */

const _claims = null;

export function useAuthOven(auth) {
  const [claims, setClaims] = useState(_claims);
  const [user, loadingUser, errorLoadingUser] =
    useAuthState(auth, {
      onUserChanged: async (user) => {
        console.log("useAuthOven:onUserChanged", user);
        if (!user) {
          return setClaims({});
        }
        if (user.isAnonymous) {
          return setClaims({});
        }

        const {claims, ...rest} =
          await getIdTokenResult(user);

        setClaims(claims);
      }
    });

  const refreshClaims = () => {
    getIdTokenResult(user, true)
      .then(({claims}) => {
        setClaims(claims);
      });
  };

  /** @type {useAuthOvenData} */
  return [
    user,
    {data: claims, refresh: refreshClaims},
    loadingUser,
    errorLoadingUser
  ];
}
