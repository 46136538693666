import React from 'react';
import PropTypes from 'prop-types';
import {Button, Card, Tag, Typography} from "antd";
import {DownArrowOutlined, PaymentIcon} from "@web/assets/icons";
import MarkdownTimeline from "./MarkdownTimeline";
import Day from "./Day";

TimelinePaymentsCard.propTypes = {
  path: PropTypes.string,
  payment: PropTypes.object,
  date: PropTypes.number,
  primary: PropTypes.bool,
  collapse: PropTypes.bool,
};

function TimelinePaymentsCard(props) {
  const [collapsed, setCollapsed] =
    React.useState(props.collapse);

  if (!props.payment) {
    return null;
  }

  const onClickCollapse = () => {
    setCollapsed(!collapsed);
  }

  const amount =
    props.payment.card?.amount ||
    +props.payment.card?.subtitle?.replaceAll(/[$,]/g, "")?.trim();

  const subtitle =
    isFinite(amount) ?
      `$${amount}` :
      props.payment.card.subtitle;

  return <Card
    style={{width: '100%'}}
    bordered={false}
    extra={<Button ghost onClick={onClickCollapse}><DownArrowOutlined /></Button>}
    title={<div className="space space-center-y">
      <PaymentIcon/>&nbsp;
      <label className="ai-text font-large card-header-title-text">
        {props.payment.card?.title} {subtitle}
      </label>&nbsp;
      <label className="font-large card-header-title-text">
        <Day ts={props.payment.date || props.date} format="dddd MMMM D[,] h:mm a"/>
      </label>
      </div>
    }
    className="card-rounded card-simple extra-round extra-padding card-body-scroll"
  >
    {!!props.payment.title &&
      <Typography.Title level={5} className="mb-0">{props.payment.title}</Typography.Title>
    }
    {/*<h6 className="ai-text">{payment.card?.subtitle} {payment.card?.title}</h6>*/}
    {!collapsed &&
      <div className="space space-col" style={{height: '80%'}}>
        <MarkdownTimeline
          markdown={props.payment.summary}
          components={props.primary ? {
            p: (props) => {
              return <Typography.Paragraph
                className="ai-text"
                style={{fontSize: '24px', lineHeight: '1.4em', marginBottom: 0}}
                // ellipsis={{rows: 6, expandable: true}}
              >{props.children}
              </Typography.Paragraph>
            }} : null}
        />
    </div>}
    <div className="space space-center-y" style={{marginTop: '20px'}}>
      {props.payment.card?.labels?.map((label) =>
      <Tag key={label} className="muted">{label}</Tag>)}
    </div>
  </Card>
}

export default TimelinePaymentsCard;
