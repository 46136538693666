import React from 'react';
import PropTypes from 'prop-types';
import {Divider} from "antd";

QuickPrompts.propTypes = {
  agentId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  pageSize: PropTypes.number,
  prompts: PropTypes.arrayOf(PropTypes.object),
  onClick: PropTypes.func,
};

/**
 * @summary QuickPrompts
 * @param {object} props
 * @param {string} props.agentId
 * @param {number} [props.pageSize=5]
 * @param {{text:string}[]} [props.prompts]
 * @param {function(question:string):void} props.onClick
 * @param {string} props.title
 * @param {string} props.className
 * @return {React.JSX.Element|null}
 */
function QuickPrompts(props) {
  if (!props.prompts?.length) {
    return null;
  }
  return <div className="space space-col" style={{width: '100%'}}>
    {/*<Divider className="no-top small-bottom" orientation="left"><span className="muted">{props.title}</span></Divider>*/}
    <div style={{width: '100%', textAlign: 'left'}}>
    {props.prompts?.map((prompt, index) => {
      return <div
        className="fat-chip"
        style={{display: 'inline-block', margin: '0 6px 10px 0', padding: '4px 24px'}}
        key={prompt.createdTs ?? index}
        onClick={() => props.onClick(prompt.text)}
      >
        <span className={`${props.className || ""}`}>{prompt.text}</span>
      </div>
    })}
    </div>
  </div>
}

export default QuickPrompts;
