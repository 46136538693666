import React from 'react';
import PropTypes from 'prop-types';
import {buildQuery} from "@web/lib/firestore.db";
import useQueryFirestore from "@web/hooks/useQueryFirestore";
import {Divider} from "antd";

RecentPromptsWrapper.propTypes = {
  agentId: PropTypes.string.isRequired,
  collectionPath: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  component: PropTypes.elementType.isRequired,
  pageSize: PropTypes.number,
  onClick: PropTypes.func,
  filters: PropTypes.object,
};

/**
 * @summary RecentPromptsWrapper
 * @param {object} props
 * @param {string} props.agentId
 * @param {string} props.collectionPath
 * @param {string} props.title
 * @param {number} [props.limit=5]
 * @param {object} [props.filters]
 * @param {function(question:string):void} props.onClick
 * @return {React.JSX.Element|null}
 */
function RecentPromptsWrapper(props) {
  const getQuery = () => {
    return buildQuery(
      props.collectionPath,
      props.filters || {},
      {
        order: [['prompt.createdTs', 'desc']],
        limit: props.limit || 5,
      });
  }
  const [pairs = []] = useQueryFirestore(getQuery())
  return React.createElement(props.component, {
    prompts: pairs.map(([, chat]) => chat.prompt),
    agentId: props.agentId,
    collectionPath: props.collectionPath,
    pageSize: props.pageSize,
    onClick: props.onClick,
    title: props.title,
  });
}

export default RecentPromptsWrapper;
