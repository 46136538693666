import React from "react";
import {Avatar, Tag, Typography} from "antd";
import ContentActions from "./ContentActions";
import {AppContext, WindowContext} from "@web/hooks/context";
import PropTypes from "prop-types";
import Day from "./Day";

ChatPrompt.propTypes = {
  path: PropTypes.string.isRequired,
  chat: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

export default function ChatPrompt(props) {
  return <ContentActions
    path={props.path}
    content={props.chat?.prompt}
    like={props.chat?.like}
    trigger="contextMenu"
  >
    <li
      className="brainstorm-message right"
      onClick={props.onClick}
    >
      <div className="space space-center-y gap-30">
        {/*<Avatar src={user.photoURL} size="large" className="chat-avatar"/>*/}
        <Tag
          closable={false}
          className="font-lg"
          style={{
            borderRadius: '26px',
            padding: '13px 42px 13px 30px',
            marginBottom: '0',
            border: 'none',
            backgroundColor: '#111b26'
          }}
          key={props.path}
        >
          <div className="space space-center-y">
          {props.chat?.prompt?.text}
            <span
              className="muted font-sm relative"
              style={{display: 'inline-block'}}
            >
              <Day ts={props.chat.prompt.createdTs} />
            </span>
          </div>
        </Tag>
      </div>
    </li>
  </ContentActions>
}
