import React from 'react';
import PropTypes from "prop-types";
import {useSelector} from "react-redux";

ContentData.propTypes = {
  component: PropTypes.elementType.isRequired,
  path: PropTypes.string.isRequired,
  dataField: PropTypes.string,
};

function ContentData({component, dataField, ...props}) {
  const item = useSelector((state) => {
    return state.Content.data?.[props.path];
  });

  if (!item) {
    return null;
  }

  return React.createElement(component, {
    ...props,
    [dataField || "data"]: item,
  });
}

export default ContentData;
