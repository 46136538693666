import React from 'react';
import PropTypes from 'prop-types';
import {Card} from "antd";
import {updatePath} from "@web/lib/firestore.db";
import now from "lodash/now";
import Day from "./Day";

ContactCard.propTypes = {
  action: PropTypes.string,
  ["action-path"]: PropTypes.string,
  name: PropTypes.string,
  onSelect: PropTypes.func,
  userId: PropTypes.string,
  activityTs: PropTypes.number,
  isPerson: PropTypes.bool,
};

function ContactCard(props) {
  const onSelect = (selectedUserId, e) => {
    console.log("ContactCard:onSelect", selectedUserId);
    e.preventDefault();
    e.stopPropagation();
    props.onSelect && props.onSelect(selectedUserId);
    const actionPath = props["action-path"];
    return actionPath &&
    updatePath(actionPath, {
      ["widget.response"]: {
        userId: selectedUserId,
        ts: now(),
      },
    }).then(() => false);
  }
  console.log("ContactCard:props", props);
  return <Card
    style={{width: 300}}
    hoverable
    bordered={false}
    key={props.userId}
    title={<span className="capitalize">{props.name || props.contact?.name}</span>}
    onClick={(e) => onSelect(props.userId, e)}
    className="card-simple card-rounded card-slim card-inline card-highlight"
    // extra={<Button onClick={(e) => onSelect(props.userId, e)} className="green-icon capitalize">{props.action}</Button>}
  >
    <div className="space space-center-y">
      <span className="muted">
        <strong>{(props.email || props.contact?.email)?.split("@")?.[1]}</strong>
      </span>
      <span className="muted uppercase" style={{fontSize: '12px', paddingTop: '3px'}}>
        <Day ts={props.activityTs || props.contact?.activityTs} format={"MMM DD YYYY"}/>
      </span>
    </div>
  </Card>
}

export default ContactCard;
