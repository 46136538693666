import React from 'react';
import PropTypes from 'prop-types';
import Toggle from "./Toggle";
import StatusActions from "./StatusActions";
import {api} from "@web/lib/api";
import {useDispatch, useSelector} from "react-redux";
import {Tag, Typography} from "antd";
import aiActions from "@web/redux/ai/actions";
import {ArrowRightOutlined} from "@ant-design/icons";
import Day from "./Day";

RecommendedTodo.propTypes = {
  eventId: PropTypes.string.isRequired,
  // activityPath: PropTypes.string.isRequired,
  primary: PropTypes.bool,
  defaultExpanded: PropTypes.bool,
  expand: PropTypes.bool,
};

function RecommendedTodo(props) {
  const dispatch = useDispatch();
  const event =
    useSelector((state) =>
      state.Content.events[props.eventId]);

  const [userTaskPath, setUserTaskPath] = React
  .useState(event.userTaskPath);

  const activeUserTaskPath =
    useSelector((state) =>
      state.AI.userTask?.path);

  if (!event) {
    return null;
  }

  if (!event?.recommendedTodo) {
    return null;
  }

  // console.log("RecommendedTodo:", event.recommendedTodo);

  const setActiveTask = () => {
    if (userTaskPath) {
      console.log("setActiveTask:activityPath:", event.activityPath);
      dispatch(aiActions.setActiveUserTask(userTaskPath));
      return;
    }
    api.call("user-createTask", {
      activityPath: event.activityPath,
      eventId: props.eventId,
    })
    .then((response) => {
      // console.log("user-createTask:response:", response);
      dispatch(aiActions.setActiveUserTask(response.data.path));
      setUserTaskPath(response.data.path);
    });
  }

  // action={[
  //   !!activityEvent.recommendedTodo &&
  //   activityEvent.recommendedTodo.status !== "completed" &&
  //   <div
  //     key="actions-1"
  //     className="space space-col"
  //     style={{paddingLeft: '22px', marginBottom: '30px', borderLeft: 'solid 4px rgba(255,255,255,.1)'}}
  //   >
  //     {/*<label className="muted font-xl">{props.contentItemActivity.recommendedTodo.dueDateOfTodo && "By " + dayjs(props.contentItemActivity.recommendedTodo.dueDateOfTodo).format("dddd MMMM D h:mm a")}</label>*/}
  //     <label
  //       className="muted font-xl">
  //       {activityEvent.recommendedTodo.goal}
  //     </label>
  //     <Typography.Text
  //       className="ai-text"
  //       style={{fontSize: '24px', lineHeight: '1.4em', marginBottom: '15px'}}
  //     >
  //       {activityEvent.recommendedTodo.summary}
  //     </Typography.Text>
  //     <h6>
  //       <div className="space space-center-y space-between">
  //         <StatusActions
  //           path={activityEvent.activityPath}
  //           itemId={activityEvent.activityPath}
  //           initialStatus={activityEvent.recommendedTodo.status}
  //           iconClassName="green-icon"
  //           actions={['done', 'dismiss']}
  //         />
  //       </div>
  //     </h6>
  //   </div> || null]
  // }
  // return <div
  //   className="space space-col gap-small"
  //   style={{paddingLeft: '22px', borderLeft: 'solid 4px rgba(255,255,255,.1)', marginBottom: '16px'}}
  //   onClick={setActiveTask}
  // >
  //   {/*<label className="muted font-xl">{props.contentItemActivity.recommendedTodo.dueDateOfTodo && "By " + dayjs(props.contentItemActivity.recommendedTodo.dueDateOfTodo).format("dddd MMMM D h:mm a")}</label>*/}
  //   <label className={`muted ${props.primary ? "font-xl" : "font-lg"}`}>
  //     {event.recommendedTodo.goal}
  //   </label>
  //   <Typography.Text
  //     className={`ai-text${props.primary ? " font-xl" : " font-lg"}`}>
  //     {event.recommendedTodo.summary}
  //     {/*style={{fontSize: '24px', lineHeight: '1.4em', marginBottom: '15px'}}*/}
  //   </Typography.Text>
  //   <h6>
  //     <div className="space space-center-y space-between">
  //       <StatusActions
  //         path={props.activityPath}
  //         initialStatus={event.recommendedTodo.status}
  //         iconClassName="green-icon"
  //         actions={['done', 'dismiss']}
  //       />
  //       {!!userTaskPath &&
  //         activeUserTaskPath === userTaskPath &&
  //         <Tag color="tan">active</Tag>
  //       }
  //     </div>
  //   </h6>
  // </div>;
  return <Toggle
      key="recommended-todo"
      trigger={!props.defaultExpanded && !props.expand && <span className="font-sm uppercase underline muted">Todo</span>}
      defaultExpanded={props.defaultExpanded}
      expand={props.expand}
    >
      <div
        className="space space-col gap-small"
        style={{paddingLeft: '22px', borderLeft: 'solid 4px rgba(255,255,255,.1)', marginBottom: '16px'}}
        onClick={setActiveTask}
      >
        {/*<label className="muted font-xl">{props.contentItemActivity.recommendedTodo.dueDateOfTodo && "By " + dayjs(props.contentItemActivity.recommendedTodo.dueDateOfTodo).format("dddd MMMM D h:mm a")}</label>*/}
        {/*<Tag color="magenta">GOAL</Tag>*/}
        <label className={`muted ${props.primary ? "font-xl" : "font-lg"}`}>
          {/*<Tag color="magenta">GOAL</Tag>*/}
          {(!!userTaskPath &&
            activeUserTaskPath === userTaskPath) ?
            <Tag color="magenta">GOAL</Tag> :
            <Tag icon={<ArrowRightOutlined />} color="magenta">GOAL</Tag>
          }
          {event.recommendedTodo.goal}
        </label>
        <Typography.Text
          className={`ai-text${props.primary ? " font-xl" : ""}`}>
          {/*<Tag color="magenta">TASK</Tag>*/}
          {event.recommendedTodo.summary} By <Day ts={event.recommendedTodo.dueDate} />.
          {/*style={{fontSize: '24px', lineHeight: '1.4em', marginBottom: '15px'}}*/}
        </Typography.Text>
        <h6>
          <div className="space space-center-y space-between">
            {event.recommendedTodo.status === "open" ?
              <StatusActions
                path={event.activityPath}
                initialStatus={event.recommendedTodo.status}
                iconClassName="green-icon"
                actions={['done', 'dismiss']}
              /> :
              <Tag>{event.recommendedTodo.status}</Tag>
            }
          </div>
        </h6>
      </div>
    </Toggle>;
}

export default RecommendedTodo;
