import React from 'react';
import PropTypes from 'prop-types';
import {Button, Card} from "antd";
import {DownArrowOutlined} from "@web/assets/icons";
import DraftEditorManager from "./DraftEditorManager";

UserTaskProgress.propTypes = {
  collapsed: PropTypes.bool,
  userTask: PropTypes.object.isRequired,
  agentAnswer: PropTypes.object,
  finished: PropTypes.bool.isRequired,
  onClickCollapse: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  onAddContextPath: PropTypes.func.isRequired,
  onRemoveContextPath: PropTypes.func.isRequired,
  onClearEmailDraftAction: PropTypes.func.isRequired,
};

function UserTaskProgress(props) {
  const [collapsed, setCollapsed] = React.useState(props.collapsed);
  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  }
  return <Card
    bordered={false}
    className="card-rounded card-simple extra-round"
    title={props.userTask?.goal || "Task"}
    extra={<Button ghost onClick={toggleCollapse}><DownArrowOutlined /></Button>}
    // bodyStyle={{padding: "0"}}
  >
    <div style={{display: collapsed ? "none" : "block"}}>
      {props.userTask?.actions?.map((action) => {
        switch (action.actionComponent) {
          case "draftEmail": {
            return <DraftEditorManager
              agentId={"ai-progress"}
              draftPath={action.draftPath}
              onAddContextPath={props.onAddContextPath}
              onRemoveContextPath={props.onRemoveContextPath}
              onClearActiveEmailDraft={props.onClearEmailDraftAction}
            />
          }
          default: {
            return null;
          }
        }
      })}
    </div>
  </Card>
}

export default UserTaskProgress;
