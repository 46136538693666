import React, {useContext, useEffect} from "react";
import {Button, Divider, Input, Space, Tabs, Typography} from "antd";
import {Link, Redirect, useLocation} from "wouter";
import TagsList from "./TagsList";
import {auth, ga} from "@web/lib/firebase";
import FeaturesList from "./FeaturesList";
import Industries from "./Industries";
import {AppContext, WindowContext} from "@web/hooks/context";
// import {notifyAndTrack} from "./notification";
import Logo from "./Logo";
// import {LikeIcon} from "@web/assets/icons";
// import {DollarCircleOutlined} from "@ant-design/icons";
import PaymentMiniCard from "./PaymentMiniCard";
import {useAuthOven} from "@web/hooks/useAuthOven";
import Loading from "./Loading";
import useDataRTDB from "@web/hooks/useDataRTDB";
// import {ref, off, get} from 'firebase/database'
import DashboardRow from "./DashboardRow";
import Login from "@ai-antd/page/Login";
import {notifyAndTrack} from "./notification";
import useFunctionsCallable from "@web/hooks/useFunctionsCallable";
import Markdown from "./Markdown";
import useLocalStorage from "@web/hooks/useLocalStorage";
import useQuerystring from "@web/hooks/useQuerystring";
import VideoLink from "./VideoLink";
import Icon from "./Icon";

const buttonWrapperStyle = {width: "220px"};

const paymentTabItems = [
  {
    key: "me",
    label: "Me",
    children: <PaymentMiniCard
      buttonId="buy_btn_1NjaTaJEZgSQPP4EYRyhfJ3s"
      amount={600}
      text="Access for you"
    />,
  },
  {
    key: "me-plus-one",
    label: "Plus 1",
    children: <PaymentMiniCard
      buttonId="buy_btn_1NjaYTJEZgSQPP4Eki4Q6Shv"
      amount={1200}
      text="Access for two"
    />,
  },
  // {
  //   key: "team",
  //   label: "Team",
  //   children: <PaymentMiniCard
  //     buttonId="buy_btn_1NjaYTJEZgSQPP4Eki4Q6Shv"
  //     amount={3000}
  //     text="Team, up to 10"
  //   />,
  // },
];
const Wrapper = ({isMobile, height, children}) => {
  return <div className={`splash-card-wrapper flex-col middle${isMobile ? " splash-card-wrapper-mobile" : ""}`}
              style={{
                minHeight: isMobile ? "101lvh" : height + "px",
                padding: "20px 10px",
                // padding: isMobile ? '20px' : '20px 12% 20px 5%',
              }}>{children}</div>;
};

const SplashCard = ({agent}) => {
  const [, setLocation] = useLocation();
  const [isMobile, , height] = useContext(WindowContext);
  const [feature = {}] = agent.features;
  return (
    <Wrapper
      isMobile={isMobile}
      height={height}
    >
      <Typography.Title type="warning" level={3}>{feature.actionVerb}.</Typography.Title>
      <Typography.Title level={3}>{feature.feature}</Typography.Title>
      <TagsList tags={feature.keywords?.slice(0, 3)} type="secondary"/>
      <Divider/>
      <Space direction="vertical" style={buttonWrapperStyle}>
        <Button type="primary" title="Get Started">
          <Typography.Link href="https://app.thescribe.ai">
            Get Started
          </Typography.Link>
        </Button>
      </Space>
      {feature?.subtitle && <div style={{marginTop: "20px"}}>
        <Typography.Text type="secondary">{feature.subtitle}</Typography.Text>
      </div>}
    </Wrapper>
  );
};

SplashCard.Page = ({
                     pauseAnimation,
                     startAnimation,
                     onTextChange,
                     index,
                     goToIndex,
                     navigateNext,
                     disableNavigation,
                     className = "",
                     children
                   }) => {
  const [isMobile, , height] = useContext(WindowContext);
  return <Wrapper isMobile={isMobile} height={height}>
    <DashboardRow>
      <div style={{width: "92%", maxWidth: 800}}>
        <div className={`space space-col splash-home-title-wrapper ${className}`}>
          {children}
        </div>
      </div>
    </DashboardRow>
  </Wrapper>;
};

SplashCard.Home = ({pauseAnimation, startAnimation, onTextChange, index, goToIndex, next}) => {
  const [isMobile, , height] = useContext(WindowContext);
  const url = new URL("https://hulu.com/");
  url.searchParams.set("id", "v-123");
  return <Wrapper isMobile={isMobile} height={height}>
    <DashboardRow>
      <div style={{width: "92%", maxWidth: 800, margin: "0 auto"}}>
        <div className="space space-col splash-home-title-wrapper">
          {isMobile ?
            <Typography.Title level={1}>
              <span className="hero-title">A</span>
              <span className="hero-title">
                  <VideoLink
                    url="https://player.vimeo.com/video/872735639?h=9d90918c85&amp;badge=0&amp;autopause=0&amp;quality_selector=1&amp;progress_bar=1&amp;player_id=0&amp;app_id=58479"
                    // method="#"
                  >brain</VideoLink>
                </span>
              <span className="hero-title">For</span>
              <span className="hero-title">Your</span>
              <span className="hero-title">Business</span>
            </Typography.Title> :
            <Logo name="TheScribe AI"/>
          }
          {/*<Markdown markdown={`## [Peace of mind](#video-link?url=${encodeURIComponent(url.toString())}) in a fast changing world.`} />*/}
          {!isMobile &&
            <Typography.Title level={1}>
              A <VideoLink className="green-color" url="https://player.vimeo.com/video/872735639?h=9d90918c85&amp;badge=0&amp;autopause=0&amp;quality_selector=1&amp;progress_bar=1&amp;player_id=0&amp;app_id=58479">brain</VideoLink> for your business.
            </Typography.Title>
          }
        </div>
      </div>
    </DashboardRow>
  </Wrapper>;
};

// const HomeWrapper = ({card, navigateNext}) => {
//   const [isMobile,,height] = useContext(WindowContext)
//   const doAction = (action, e) => {
//     ga.click(e)
//     notifyAndTrack.info(action || 'Coming soon')
//   }
//   return <Wrapper isMobile={isMobile} height={height}>
//     <Typography.Title type="warning" level={2}>{card.title}</Typography.Title>
//     <h6>{card.subTitle}</h6>
//     <TagsList tags={card.tags} type="secondary" />
//     <Divider />
//     <Space direction="vertical">
//       <Space>
//         {card?.actions?.map(([label, action]) => {
//           return <Button key={label} size="large" onClick={(e) => doAction(action, e)}>
//             {label}
//           </Button>
//         })}
//         {/*<Button title="Splash Landing: Get Started" size="large" onClick={firstButtonOnClick}>*/}
//         {/*  Get Started*/}
//         {/*</Button>*/}
//         {/*<Button title="Splash Landing: Tell Me More" size="large" icon={<RightOutlined />} onClick={navigateNext}>*/}
//         {/*  Tell me more*/}
//         {/*</Button>*/}
//       </Space>
//       {card?.extra?.text && !card?.extra?.hidden && <Link className="splash-extra-link" to={card.extra?.url}>
//         {card.extra.text}
//       </Link>
//       }
//     </Space>
//   </Wrapper>
// }

SplashCard.Apps = ({pauseAnimation, startAnimation}) => {
  const [isMobile, , height] = useContext(WindowContext);
  // const documentsUrl = new URL("https://hulu.com/");
  // const emailUrl = new URL("https://hulu.com/");
  // const projectsUrl = new URL("https://hulu.com/");
  // const invoicesUrl = new URL("https://hulu.com/");
  // const drivesUrl = new URL("https://hulu.com/");
  const appsUrl = new URL("https://hulu.com/");
  const mistakesUrl = new URL("https://hulu.com/");
  // const experienceUrl = new URL("https://hulu.com/");
  // `so you just ask and get instant access to information buried in [files](#video?url=${encodeURIComponent(documentsUrl.toString())}), [email](#video?url=${encodeURIComponent(emailUrl.toString())}), notes, [project management and customer](#video?url=${encodeURIComponent(projectsUrl.toString())}) tools, [invoices](#video?url=${encodeURIComponent(invoicesUrl.toString())}), [drives](#video?url=${encodeURIComponent(drivesUrl.toString())}), spreadsheets and [apps](#video?url=${encodeURIComponent(appsUrl.toString())}), all from one place, so you meet your deadlines with less stress, [prevent costly mistakes](#video-link?url=${encodeURIComponent(mistakesUrl.toString())}), and improve the [experience](#video-link?url=${encodeURIComponent(experienceUrl.toString())}) of work.`
  return <Wrapper isMobile={isMobile} height={height}>
    <DashboardRow>
      <div style={{width: "100%", maxWidth: 600}}>
        <div className="space space-col">
          <Typography.Title>Your business brain</Typography.Title>
          {/*<Typography.Text>*/}
          {/*  that bridges the apps you use everyday to give you instant access to your files, activity, projects, and status updates, plus intelligent reminders, all in one place, even when you don't remember that name or where you saved that file.*/}
          {/*</Typography.Text>*/}
          <Typography.Text>
            <Markdown
              markdown={`[Just ask your Scribe.](#video-link?url=${encodeURIComponent(mistakesUrl.toString())}) Instantly know what has been done and what to do next, plus access all your data from one place and never jump between [apps, emails, drives and documents](#video-link?url=${encodeURIComponent(appsUrl.toString())}) to find basic facts.`}/>
          </Typography.Text>
          {/*// with less stress, prevent [costly mistakes](#video-link?url=${encodeURIComponent(mistakesUrl.toString())}), and improve the [experience](#video-link?url=${encodeURIComponent(experienceUrl.toString())}) of work.*/}
        </div>
      </div>
    </DashboardRow>
  </Wrapper>;
};
SplashCard.Assistant = ({pauseAnimation, startAnimation}) => {
  const [isMobile, , height] = useContext(WindowContext);
  const answersUrl = new URL("https://hulu.com/");
  const todosUrl = new URL("https://hulu.com/");
  const remembersUrl = new URL("https://hulu.com/");
  return <Wrapper isMobile={isMobile} height={height}>
    <DashboardRow>
      <div style={{width: "100%", maxWidth: 600}}>
        <div className="space space-col">
          <Typography.Title>Your office manager</Typography.Title>
          <Typography.Text>
            <Markdown
              markdown={`who never sleeps, [answers your questions](#video-link?url=${encodeURIComponent(answersUrl.toString())}), consistently performs the [tasks you assign](#video-link?url=${encodeURIComponent(todosUrl.toString())}), simplifies employee [training](#video-link?url=${encodeURIComponent(answersUrl.toString())}), and [remembers](#video-link?url=${encodeURIComponent(remembersUrl.toString())}) all those important details that keep your business and team on track.`}/>
          </Typography.Text>
        </div>
      </div>
    </DashboardRow>
  </Wrapper>;
};
SplashCard.Insights = ({pauseAnimation, startAnimation}) => {
  const [isMobile, , height] = useContext(WindowContext);
  const workSmarterUrl = new URL("https://hulu.com/");
  return <Wrapper isMobile={isMobile} height={height}>
    <DashboardRow>
      <div style={{width: "100%", maxWidth: 600}}>
        <div className="space space-col">
          <Typography.Title>Your coach</Typography.Title>
          <Typography.Text>
            <Markdown
              markdown={`to gathers valuable insights to help you visualize the people, activities, and patterns that shape your days, so that you can become more deliberate in your choices - [work smarter and live better.](#video-link?url=${encodeURIComponent(workSmarterUrl.toString())})`}/>
          </Typography.Text>
        </div>
      </div>
    </DashboardRow>
  </Wrapper>;
};
SplashCard.Signup = ({pauseAnimation, startAnimation, goToIndex, next, disableNavigation}) => {
  const [isMobile, , height] = useContext(WindowContext);
  const [, user] = useContext(AppContext);
  const querystring = useQuerystring();
  // const [skip, setSkip] = React.useState(false);

  useEffect(() => {
    disableNavigation(true);
  }, []);
  // useEffect(() => {
  //   // if (!user) return disableNavigation(true)
  //   return goToIndex(index + 1);
  // }, [user]);
  // console.log('user context', user);
  if (user) return <Redirect to="/verify"/>;

  // return <Wrapper>
  //   <div style={{width: '100%', height, maxWidth: 600}}>
  //   </div>
  // </Wrapper>

  // console.log('SplashCard.Signup Render', user)
  const providers = ["email"];
  if (querystring.demo) {
    providers.push("google.com");
    providers.push("microsoft.com");
  }
  return <Wrapper
    isMobile={isMobile}
    height={height}
  >
    {user ?
      null :
      <Login providers={providers} redirectTo="/verify" subtitle="Verify your email to continue"/>
    }
  </Wrapper>;
};
SplashCard.Invite = ({pauseAnimation, startAnimation, onTextChange, index, goToIndex, next, disableNavigation}) => {
  const [isMobile, , height] = useContext(WindowContext);
  const [user, claims, loadingUser, errorLoadingUser] = useAuthOven(auth);
  const [seeGenesis] = useLocalStorage("seeGenesis");
  const {verified = false} = claims?.data || {};
  const [inviteCode, setInviteCode] = React.useState("");
  const [verifyInviteCode, loadingFunction, errorLoadingFunction] = useFunctionsCallable("user-verifyInviteCode");

  useEffect(() => {
    if (loadingUser) return;
    disableNavigation(true);
  }, [loadingUser, verified]);

  useEffect(() => {
    if (loadingUser) return;
    if (verified === true) {
      return;
    }
    if (inviteCode.length === 4) {
      verifyInviteCode({inviteCode})
        .then(({data}) => {
          const {accepted, invitedByName, errorMessage, userNumber} = data;
          if (!accepted) {
            notifyAndTrack.warn("Oops", errorMessage || "Please try again");
          }
          if (accepted) {
            claims.refresh();
            // setInvitedByName(invitedByName);
          }
        })
        .catch(() => {
          notifyAndTrack.error("An error occurred", "Unable to validate your invitation");
        });
    }
  }, [user, inviteCode, verified]);

  if (loadingUser) return <Loading/>;
  if (!claims.data) return null;

  return <Wrapper isMobile={isMobile} height={height}>
    <DashboardRow>
      <div style={{width: "100%", maxWidth: 600}}>
        <div className="space space-col">
          {verified ? <h1>You are verified</h1> : <Logo name="TheScribe AI"/>}
          {verified ?
            <Button ghost className="icon-btn icon-left"><a href="/">
              <div className="space space-center-y"><Icon name="RightArrowOutlined"/> <span
                className="green-color">Continue</span></div>
            </a></Button> :
            <Input.Password
              maxLength={4}
              placeholder="enter your invite code"
              size="large"
              bordered={false}
              className={`scribe-input-bold ${isMobile ? "mt-12" : "mt-20"}`}
              onFocus={() => {
                // console.log('onFocus')
                pauseAnimation();
              }}
              onBlur={startAnimation}
              onChange={(e) => setInviteCode(e.target.value)}
            />
          }
          {!verified && !!seeGenesis &&
            <div style={{marginTop: "30px"}} className="space space-center-y"><span>or&nbsp;</span><Link
              href="/genesis-access" className="gold-icon underline">Join our Genesis Community Beta</Link></div>}
        </div>
      </div>
    </DashboardRow>
  </Wrapper>;
};

SplashCard.Subscribe = ({pauseAnimation, startAnimation}) => {
  const [isMobile, , height] = useContext(WindowContext);
  const [, claims, loadingUser, errorLoadingUser] = useAuthOven(auth);
  const [invitesAccepted, loadingInvitesCount, errorLoadingInvitesCount] = useDataRTDB("/invites/accepted");
  const {subscribed = false} = claims?.data || {};
  const [, setLocation] = useLocation();
  useEffect(() => {
    if (subscribed) return setLocation("/subscribed");
  }, [subscribed]);

  if (loadingUser) return null;
  if (errorLoadingUser) return null;

  const invitesCount = loadingInvitesCount ? "..." : invitesAccepted;
  return <Wrapper isMobile={isMobile} height={height}>
    <DashboardRow>
      <div style={{width: "100%", maxWidth: 600}}>
        <div className="space space-col">
          <Typography.Title>You are #{invitesCount}</Typography.Title>
          <Typography.Text>
            of our Genesis Cohort and we are excited to work together to transform the way you get work done. All
            options below include full platform access throughout the beta program, plus an additional 12 months after
            the beta program ends.
          </Typography.Text>
          <br/>
        </div>
        <Tabs
          className="tabs-bar-no-underline payment-mini-tabs"
          items={paymentTabItems}
          type="card"
        />
        <div style={{width: "100%", paddingTop: "10px"}}>
          <span className="ai-text">This is a one-time payment</span>
        </div>
      </div>
    </DashboardRow>
  </Wrapper>;
};

const Summary = ({agents = {}, workspace = {}}) => {
  const [, setLocation] = useLocation();
  const [isMobile, , height] = useContext(WindowContext);
  const goToSignup = e => {
    ga.click(e);
    setLocation(`/sign-up`);
  };
  return <Wrapper isMobile={isMobile} height={height}>
    <div style={{width: "100%", maxWidth: 600}}>
      <Typography.Title level={2}>TheScribe AI</Typography.Title>
      <Tabs
        items={[
          {
            label: "Features",
            key: "features",
            children: <FeaturesList
              agents={agents}
              agentIds={workspace.agentIds}
            />
          },
          {
            label: "Industries",
            key: "industries",
            children: <Industries/>
          }
        ]}
        tabBarExtraContent={{
          // left: <Button ghost onClick={refreshConfig}><DownloadOutlineIcon /></Button>,
          // right: isMobile &&
          //   <Link to="/login" onClick={ga.click}>
          //     <Button
          //       title="Start Now: Splash Last"
          //       type="primary"
          //     >
          //       Start Now
          //     </Button>
          //   </Link>
        }
        }
      />
      {/*<Divider />*/}
      <Button
        size="large"
        type="primary"
        block
        onClick={goToSignup}>
        Get Started Today
      </Button>
      {/*<Divider />*/}
    </div>
  </Wrapper>;
};

// SplashCard.HomeWrapper = HomeWrapper
SplashCard.Summary = Summary;
export default SplashCard;
