import React from "react";
import PropTypes from "prop-types";
import {CheckIcon, DeleteIcon, DownIcon, EyeIcon, UpRightArrowOutlined, XIcon} from "@web/assets/icons";
import {notifyAndTrack} from "./notification";
import {Button, Space, Tag, Tooltip} from "antd";
import now from "lodash/now";
import {AppContext} from "@web/hooks/context";
import DevButton from "./DevButton";
import CallButton from "./CallButton";
import useAddFirestore from "@web/hooks/useAddFirestore";

StatusActions.propTypes = {
  path: PropTypes.string.isRequired,
  initialStatus: PropTypes.string,
  iconClassName: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.oneOf(['done', 'dismiss', 'remind'])),
};

function StatusActions({path, initialStatus, iconClassName, actions = [], extra= null}) {
  const [,user] = React.useContext(AppContext);
  const [status, setStatus] = React.useState(initialStatus);
  const [actionPath] = React.useState(path + `/crud/${user.uid}/update`);
  const [addItem] = useAddFirestore(actionPath);
  const [processing, setProcessing] = React.useState(false);

  if (!path) return null;
  const onClick = (action, e, message) => {
    e?.stopPropagation();
    e?.preventDefault();
    addItem({data: {status: action, statusTs: now()}, ts: now()})
      .then(() => setStatus(action))
      .then(() => setProcessing(true))
      .then(() => message && notifyAndTrack.success(message, "", {path: actionPath, action}));
    return false;
  }

  switch (status) {
    case 'done':
    case 'completed':
      return <Space size="small">
        <Tooltip title="Done!" placement="bottom">
          <Tag className="capitalize"><h6>{status}</h6></Tag>
        </Tooltip>
      </Space>;
    case 'dismiss':
      return <Space size="small">
        <Tooltip title="Dismissed" placement="bottom">
          <Button ghost size="small"><XIcon className="muted-icon" /></Button>
        </Tooltip>
        <DevButton path={path} loader="" trigger={["contextMenu", "hover"]} />
      </Space>;
    case 'delete':
      return <Space size="small">
        <Tooltip title="Dismissed" placement="bottom">
          <Button ghost size="small"><DeleteIcon className="muted-icon" /></Button>
        </Tooltip>
        <DevButton path={path} loader="" trigger={["contextMenu", "hover"]} />
      </Space>;
    case 'remind':
      return <Space size="small">
        <Tooltip title="Will remind" placement="bottom">
          <Button ghost size="small"><DownIcon className="muted-icon" /></Button>
        </Tooltip>
        <DevButton path={path} loader="" trigger={["contextMenu", "hover"]} />
      </Space>;
    default:
      return <Space size="small">
        {actions.includes('dismiss') && <Tooltip title="Dismiss" placement="bottom"><Button ghost size="small" onClick={(e) => onClick('dismiss', e, 'Dismissed')}><XIcon className={iconClassName} /></Button></Tooltip>}
        {actions.includes('remind') && <Tooltip title="Remind" placement="bottom"><Button ghost size="small" onClick={(e) => onClick('remind', e, "Ok, I'll follow up later")}><DownIcon className={iconClassName} /></Button></Tooltip>}
        {actions.includes('done') && <Tooltip title="Done" placement="bottom"><Button ghost size="small" onClick={(e) => onClick('done', e, "Done!")}><CheckIcon className={iconClassName} /></Button></Tooltip>}
        {/*{actions.includes('review') && <Tooltip title="Review" placement="bottom"><Button ghost size="small" onClick={(e) => onClick('review')}><EyeIcon className={iconClassName} /></Button></Tooltip>}*/}
        {actions.includes('reply') && <Tooltip title="Reply" placement="bottom"><Button ghost size="small"><UpRightArrowOutlined className={iconClassName} /></Button></Tooltip>}
        {actions.includes('call') && <Tooltip title="Dismiss" placement="bottom"><CallButton className={iconClassName} /></Tooltip>}
        {actions.includes('delete') &&
          <Tooltip
            title="Delete"
            placement="bottom"
          >
            <Button
              ghost
              size="small"
              onClick={(e) => onClick('delete', e, 'Deleted')}
            >
              <DeleteIcon className={iconClassName} />
            </Button>
          </Tooltip>}
        {/*<DevButton path={path} loader="" trigger={["contextMenu", "hover"]} />*/}
        {extra}
      </Space>
  }
}

export default StatusActions;
