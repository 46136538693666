import React, {useContext} from "react";
import {Redirect} from "wouter";
import {AppContext} from "@web/hooks/context";

const RequireVerification = ({children}) => {
  const [,user, claims] = useContext(AppContext)

  if (!user) {
    return <Redirect to="/sign-up"/>
  }
  if (user.isAnonymous) {
    return <Redirect to="/sign-up"/>
  }

  return (claims.data?.verified) ?
    children :
    <Redirect to="/verify" />;
}

export default RequireVerification
