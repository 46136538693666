import React, {useContext} from "react";
import {Button, List, Divider, Affix, Typography} from "antd";
import {AppContext, TimelineContext} from "@web/hooks/context";
import {XIcon} from "@web/assets/icons";
import PropTypes from "prop-types";
// import dayjs from "dayjs";
import Loading from "../Loading";
import DetailItem from "./DetailItem";
// import HashPager from "../HashPager";
import {useDispatch, useSelector} from "react-redux";
// import contentActions from "@web/redux/content/actions";
// import MembersList from "../MembersList";

DetailTimeline.propTypes = {
  path: PropTypes.string,
  item: PropTypes.object,
  daysList: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
  errorLoading: PropTypes.bool,
  onClose: PropTypes.func,
}

function DetailTimeline(props) {
  // const dispatch = useDispatch();
  const [, user] =
    useContext(AppContext);

  const itemsList = useSelector((state) => {
    return state.Content?.activity?.[props.path]?.itemsList;
  });

  // const sortedDaysList =
  //   props.daysList ?
  //     props.daysList
  //     .sort().reverse()
  //     .map((dateKey) => ({
  //       label: dayjs(dateKey).format("MMMM DD"),
  //       key: dateKey,
  //     })) :
  //     [];
  // const onClickItem = (itemPath) => {
  //   dispatch(contentActions.loadContext(itemPath));
  // }

  if (!user?.uid) {
    return null
  }

  if (props.loading) {
    return <Loading/>;
  }

  return <div
    className="space space-col"
    style={{width: '100%', maxWidth: '100%', margin: '0 auto'}}
  >
    <TimelineContext.Provider value={{filter: []}}>
      <Affix
        offsetTop={0}
        target={() =>
          document
          .getElementById("dashboard-detail-wrapper")}
      >
        <div className="space space-center-y">
        {/*  <HashPager*/}
        {/*  direction="horizontal"*/}
        {/*  list={sortedDaysList}*/}
        {/*  style={{height: '60px', width: '100%'}}*/}
        {/*  prefix={<Button*/}
        {/*    ghost*/}
        {/*    size="large"*/}
        {/*    className="icon-btn icon-left"*/}
        {/*    onClick={props.onClose}*/}
        {/*  >*/}
        {/*    <XIcon className="green-icon" />*/}
        {/*  </Button>}*/}
        {/*/>*/}
          <Button
            ghost
            size="large"
            className="icon-btn icon-left"
            onClick={props.onClose}
            style={{marginLeft: '6px'}}
          >
            <XIcon className="green-icon" />
          </Button>
        <Typography.Text className="font-xl bolded">
          {props.item.title || props.item.subject || props.item.originFilename}
        </Typography.Text>
        </div>
      </Affix>
      <Divider className="small no-top" />
      {/*<MembersList*/}
      {/*  itemPath={props.path}*/}
      {/*  minCount={1}*/}
      {/*/>*/}
      <List
        className="list-item-no-divider"
        dataSource={itemsList}
        renderItem={([itemPath, date], index) => {
          return <List.Item
            id={itemPath}
            key={itemPath + index}
          >
            <DetailItem
              contextPath={props.path}
              path={itemPath}
              date={date}
              primary={index === 0}
              collapsed={index > 0}
              // onClick={() => onClickItem(itemPath)}
            />
            {/*<span>{itemPath.split("/")[2]}</span>*/}
          </List.Item>
        }}
      />
    </TimelineContext.Provider>
  </div>
}

export default DetailTimeline;
