/** @namespace user.onboarding */
import React, {useContext, useEffect} from "react";
import {Button, Card, Divider, Input, List, Progress, Switch, Typography} from "antd";
import {Link, useLocation} from "wouter";
import {AppContext, WindowContext} from "@web/hooks/context";
import Loading from "../Loading";
import DashboardRow from "../DashboardRow";
import {notifyAndTrack} from "../notification";
import Icon from "../Icon";
import {FeatherIcon, RightArrowOutlined} from "@web/assets/icons";
import MemoryOriginDate from "../MemoryOriginDate";
import AppIntegrationsData from "@web/ui/containers/AppIntegrationsData";
import AppIntegrationsList from "../AppIntegrationsList";
import {Fields, updatePath} from "@web/lib/firestore.db";
import useDocumentFirestore from "@web/hooks/useDocumentFirestore";
import now from "lodash/now";
import PropTypes from "prop-types";

function Wrapper(props) {
  return <div
    className={`splash-card-wrapper flex-col middle${props.isMobile ? " splash-card-wrapper-mobile" : ""}`}
    style={{minHeight: props.isMobile ? "101lvh" : props.height + "px", padding: "20px 10px"}}
  >
    {props.children}
  </div>
}

PropTypes.Welcome = {
  setDisableNavigation: PropTypes.func.isRequired,
  pauseAnimation: PropTypes.func.isRequired,
  startAnimation: PropTypes.func.isRequired,
  navigateNext: PropTypes.func.isRequired,
  markCompleteAndNavigateNext: PropTypes.func.isRequired,
}

export function Welcome(props) {
  const [isMobile,, height] = useContext(WindowContext);
  return <Wrapper isMobile={isMobile} height={height}>
    <DashboardRow>
      <div style={{width: "92%", maxWidth: 800, margin: "0 auto"}}>
        <div className="space space-col splash-home-title-wrapper">
          <Typography.Title level={1}>Let's get started</Typography.Title>
          <Typography.Text>
            You'll set up your AI brain, connect your tools, and launch your new AI ally in just a few minutes
          </Typography.Text>
          <br/>
          <div style={{width: "100%", paddingTop: "10px"}}>
            <Button size="large" onClick={() => props.markCompleteAndNavigateNext(props.name)}>
              <h4 className="space space-center-y green-color">Begin <RightArrowOutlined /></h4>
            </Button>
          </div>
          {/*<Markdown markdown={`# A [brain](#video-link?url=${encodeURIComponent("https://player.vimeo.com/video/872735639?h=9d90918c85&amp;badge=0&amp;autopause=0&amp;quality_selector=1&amp;progress_bar=1&amp;player_id=0&amp;app_id=58479")}) for your business.`}/>}*/}
        </div>
      </div>
    </DashboardRow>
  </Wrapper>
}

export const Team = (props) => {
  const [isMobile, , height] = useContext(WindowContext);
  return <Wrapper isMobile={isMobile} height={height}>
    <DashboardRow>
      <div style={{width: "100%", maxWidth: 600}}>
        <div className="space space-col">
          <Typography.Title>Here's Your Team</Typography.Title>
          <List size="large">
            <List.Item actions={[<Button size="large" ghost><Icon name="XIcon"/></Button>]}>
              <div className="space space-center-y"><h2>Darin</h2><span className="muted"> @coupdetatsf.com</span></div>
            </List.Item>
            <List.Item actions={[<Button size="large" ghost><Icon name="XIcon"/></Button>]}>
              <div className="space space-center-y"><h2>Tommy</h2><span className="muted"> @coupdetatsf.com</span></div>
            </List.Item>
            <List.Item actions={[<Button size="large" ghost><Icon name="XIcon"/></Button>]}>
              <div className="space space-center-y"><h2>Allison</h2><span className="muted"> @coupdetatsf.com</span>
              </div>
            </List.Item>
            <List.Item actions={[<Button size="large" ghost><Icon name="XIcon"/></Button>]}>
              <div className="space space-center-y"><h2>Benjamin</h2><span className="muted"> @coupdetatsf.com</span>
              </div>
            </List.Item>
            <List.Item actions={[<Button size="large" ghost><Icon name="XIcon"/></Button>]}>
              <div className="space space-center-y"><h2>Anthony</h2><span className="muted"> @coupdetatsf.com</span>
              </div>
            </List.Item>
            <List.Item actions={[<Button size="large" ghost><Icon name="XIcon"/></Button>]}>
              <div className="space space-center-y"><h2>Evelyn</h2><span className="muted"> @coupdetatsf.com</span>
              </div>
            </List.Item>
            <List.Item actions={[<Button size="large" ghost><Icon name="XIcon"/></Button>]}>
              <div className="space space-center-y"><h2>Valerie</h2><span className="muted"> @coupdetatsf.com</span>
              </div>
            </List.Item>
            <List.Item actions={[<Button size="large" ghost><Icon name="XIcon"/></Button>]}>
              <div className="space space-center-y"><h2>Mary</h2><span className="muted"> @coupdetatsf.com</span></div>
            </List.Item>
          </List>
          <div style={{width: "100%", paddingTop: "0"}}>
            <Divider className="small-bottom" />
            <Button size="large" onClick={() => props.markCompleteAndNavigateNext(props.name)}>
              <h4 className="space space-center-y green-color">Continue <RightArrowOutlined/></h4></Button>
          </div>
          {/*// with less stress, prevent [costly mistakes](#video-link?url=${encodeURIComponent(mistakesUrl.toString())}), and improve the [experience](#video-link?url=${encodeURIComponent(experienceUrl.toString())}) of work.*/}
        </div>
      </div>
    </DashboardRow>
  </Wrapper>;
};

export const Origin = (props) => {
  const [isMobile, , height] = useContext(WindowContext);
  return <Wrapper isMobile={isMobile} height={height}>
    <DashboardRow>
      <div style={{width: "100%", maxWidth: 600}}>
        <div className="space space-col">
          <Typography.Title>Origin Date</Typography.Title>
          <Typography.Text>
            How far back should we go when fetching data from email, drives, and other apps that you choose to connect to your AI brain?
          </Typography.Text>
          <br/>
          <MemoryOriginDate />
          <div style={{width: "100%", paddingTop: "10px"}}>
            <Divider />
            <Button size="large" onClick={() => props.markCompleteAndNavigateNext(props.name)}>
              <h4 className="space space-center-y green-color">Next <RightArrowOutlined/></h4>
            </Button>
          </div>
        </div>
      </div>
    </DashboardRow>
  </Wrapper>
};

export const Voice = (props) => {
  const [isMobile, , height] = useContext(WindowContext);
  const [, user] = useContext(AppContext);

  const saveVoice = (voice) => {
    updatePath(`user/${user.uid}`, {
      voice: Fields.arrayUnion(voice),
    }).then(() => notifyAndTrack.success("Success", "Voice added"))
  };

  return <Wrapper isMobile={isMobile} height={height}>
    <DashboardRow>
      <div style={{width: "100%", maxWidth: 600}}>
        <div className="space space-col">
          <Typography.Title>Voice</Typography.Title>
          <List size="large">
            <List.Item
              actions={[<Button key="remove" size="large" ghost onClick={(e) => saveVoice("phone")}><Icon name="XIcon"/></Button>,
                <Button key="keep" size="large" ghost><Icon name="CheckIcon"/></Button>]}>
              <div className="space space-center-y"><h2>Phone</h2></div>
            </List.Item>
            <List.Item actions={[<Button key="remove" size="large" ghost onClick={(e) => saveVoice("meetings")}><Icon
              name="XIcon"/></Button>, <Button key="keep" size="large" ghost><Icon name="CheckIcon"/></Button>]}>
              <div className="space space-center-y"><h2>Meetings</h2></div>
            </List.Item>
            <List.Item actions={[<Button key="remove" size="large" ghost onClick={(e) => saveVoice("in-person")}><Icon
              name="XIcon"/></Button>, <Button key="keep" size="large" ghost><Icon name="CheckIcon"/></Button>]}>
              <div className="space space-center-y"><h2>In-Person</h2></div>
            </List.Item>
          </List>
          <div style={{width: "100%", paddingTop: "10px"}}>
            <Divider/>
            <Button size="large" onClick={() => props.markCompleteAndNavigateNext(props.name)}>
              <h4 className="space space-center-y green-color">Next <RightArrowOutlined/></h4></Button>
          </div>
        </div>
      </div>
    </DashboardRow>
  </Wrapper>
};

export const Connect = (props) => {
  const [isMobile,, height] = useContext(WindowContext);
  return <Wrapper isMobile={isMobile} height={height}>
    <DashboardRow>
      <div style={{width: "100%", maxWidth: 600}}>
        <div className="space space-col">
          <Typography.Title>Connect Your Email</Typography.Title>
          <br/>
          <Card className="card-simple card-rounded card-no-header" bordered={false}>
            <AppIntegrationsData filter="all" tags={["email"]}>
              <AppIntegrationsList/>
            </AppIntegrationsData>
          </Card>
          {/*<Typography.Text>*/}
          {/*  <Markdown*/}
          {/*    markdown={`to gathers valuable insights to help you visualize the people, activities, and patterns that shape your days, so that you can become more deliberate in your choices - [work smarter and live better.](#video-link?url=${encodeURIComponent(workSmarterUrl.toString())})`}/>*/}
          {/*</Typography.Text>*/}
        </div>
        <div style={{width: "100%", paddingTop: "10px"}}>
          <br/>
          <Typography.Text className="ai-text">
            Securely connect your email account. Choose Gmail or Outlook based on which company hosts your business email, then follow the prompts to connect your account.
          </Typography.Text>
        </div>
        <div style={{width: "100%", paddingTop: "10px"}}>
          <Divider/>
          <Button size="large" onClick={() => props.markCompleteAndNavigateNext(props.name)}>
            <h4 className="space space-center-y green-color">Next <RightArrowOutlined/></h4></Button>
        </div>
      </div>
    </DashboardRow>
  </Wrapper>
};

export const Tools = (props) => {
  const [isMobile, , height] = useContext(WindowContext);
  return <Wrapper isMobile={isMobile} height={height}>
    <DashboardRow>
      <div style={{width: "100%", maxWidth: 600}}>
        <div className="space space-col">
          <Typography.Title>Connect Your Tools</Typography.Title>
          <Typography.Text>
            Connect the apps, drives, and others tools that you use.
          </Typography.Text>
          <br/>
          <Card className="card-simple card-rounded card-no-header" bordered={false}>
            <AppIntegrationsData filter="all" tags={["drives", "sheets"]}>
              <AppIntegrationsList/>
            </AppIntegrationsData>
          </Card>
          <div style={{width: "100%", paddingTop: "10px"}}>
            <Typography.Text className="ai-text">
              Click on the buttons as needed above, follow the prompts to connect your tools and apps.
            </Typography.Text>
          </div>
          <div style={{width: "100%", paddingTop: "10px"}}>
            <Divider/>
            <Button size="large" onClick={() => props.markCompleteAndNavigateNext(props.name)}>
              <h4 className="space space-center-y green-color">Next <RightArrowOutlined/></h4></Button>
          </div>
        </div>
      </div>
    </DashboardRow>
  </Wrapper>
};

export const Terms = (props) => {
  const [isMobile, , height] = useContext(WindowContext);

  useEffect(() => {
    props.setDisableNavigation(true);
  }, []);

  return <Wrapper isMobile={isMobile} height={height}>
    <DashboardRow>
      <div style={{width: "100%", maxWidth: 600}}>
        <div className="space space-col">
          <Typography.Title>Privacy by Default</Typography.Title>
          <Typography.Text>
            We do not sell or share customer data<br/><span className="muted">By continuing you agree to our <Link to="/terms">Terms of Service</Link> and <Link to="/privacy">Privacy policy</Link></span>
          </Typography.Text>
          {/*<Switch style={{width: 80}} checkedChildren="Agree" unCheckedChildren="Disagree" defaultChecked />*/}
          <br/>
        </div>
        <div style={{width: "100%", paddingTop: "10px"}}>
          <Divider/>
          <Button size="large" onClick={() => props.markCompleteAndNavigateNext(props.name)}>
            <h4 className="space space-center-y green-color">Continue <RightArrowOutlined/></h4></Button>
        </div>
        {/*<div style={{width: "100%", paddingTop: "10px"}}>*/}
        {/*  <span className="ai-text">You can add a linked account later</span>*/}
        {/*</div>*/}
      </div>
    </DashboardRow>
  </Wrapper>
};

export const LinkedAccounts = (props) => {
  const [isMobile, , height] = useContext(WindowContext);
  const [, user] = useContext(AppContext);
  const [userInfo] = useDocumentFirestore(["user", user.uid]);

  const saveEmail = (linkedEmail) => {
    updatePath(`user/${user.uid}`, {
      linkedEmail,
    });
      // .then(() => notifyAndTrack.success("Success", "Your linked email has been saved"))
  };

  return <Wrapper isMobile={isMobile} height={height}>
    <DashboardRow>
      <div style={{width: "100%", maxWidth: 600}}>
        <div className="space space-col">
          <Typography.Title>Additional Email</Typography.Title>
          <Typography.Text>
            You can add a linked email account to your profile. This is typically used for shared emails like support@
            or info@ but may be used for other cases as well.
          </Typography.Text>
          <Input
            maxLength={100}
            placeholder={userInfo?.linkedEmail || "Email"}
            size="large"
            bordered={false}
            className={`input-bold ${isMobile ? "mt-12" : "mt-20"}`}
            onFocus={props.pauseAnimation}
            onBlur={props.startAnimation}
            onChange={(e) => saveEmail(e.target.value)}
          />
          <br/>
        </div>
        <div style={{width: "100%", paddingTop: "10px"}}>
          <Divider/>
          <Button size="large" onClick={() => props.markCompleteAndNavigateNext(props.name)}>
            <h4 className="space space-center-y green-color">Next <RightArrowOutlined/></h4></Button>
        </div>
        {/*<div style={{width: "100%", paddingTop: "10px"}}>*/}
        {/*  <span className="ai-text">You can add a linked account later</span>*/}
        {/*</div>*/}
      </div>
    </DashboardRow>
  </Wrapper>;
};

export const Websites = (props) => {
  const [isMobile, , height] = useContext(WindowContext);
  const [, user] = useContext(AppContext);

  const saveWebsite = (website) => {
    updatePath(`user/${user.uid}`, {
      websites: Fields.arrayUnion(website),
    }).then(() => notifyAndTrack.success("Success", "Website added"))
  };

  return <Wrapper isMobile={isMobile} height={height}>
    <DashboardRow>
      <div style={{width: "100%", maxWidth: 600}}>
        <div className="space space-col">
          <Typography.Title>Favorite Websites</Typography.Title>
          <Typography.Text>
            Add your business website, social media, and other sites you frequently visit online
          </Typography.Text>
          <Input
            maxLength={100}
            placeholder="https://"
            size="large"
            bordered={false}
            className={`input-bold ${isMobile ? "mt-12" : "mt-20"}`}
            onFocus={props.pauseAnimation}
            onBlur={props.startAnimation}
            onChange={(e) => saveWebsite(e.target.value)}
          />
          <Input
            maxLength={100}
            placeholder="Facebook"
            size="large"
            bordered={false}
            className={`input-bold ${isMobile ? "mt-12" : "mt-20"}`}
            onFocus={props.pauseAnimation}
            onBlur={props.startAnimation}
            onChange={(e) => saveWebsite(e.target.value)}
          />
          <Input
            maxLength={100}
            placeholder="Instagram"
            size="large"
            bordered={false}
            className={`input-bold ${isMobile ? "mt-12" : "mt-20"}`}
            onFocus={props.pauseAnimation}
            onBlur={props.startAnimation}
            onChange={(e) => saveWebsite(e.target.value)}
          />
          <br/>
        </div>
        <div style={{width: "100%", paddingTop: "10px"}}>
          <Divider/>
          <Button size="large" onClick={() => props.markCompleteAndNavigateNext(props.name)}>
            <h4 className="space space-center-y green-color">Next <RightArrowOutlined/></h4></Button>
        </div>

        {/*<div style={{width: "100%", paddingTop: "10px"}}>*/}
        {/*  <span className="ai-text">You can add websites to your account later</span>*/}
        {/*</div>*/}
      </div>
    </DashboardRow>
  </Wrapper>;
};

export const Training = (props) => {
  const [isMobile, , height] = useContext(WindowContext);
  const [, user] = useContext(AppContext);
  const [userInfo, loadingUserInfo] =
    useDocumentFirestore(["user", user.uid]);

  const startTraining = () => {
    updatePath(`user/${user.uid}`, {
      initialLoadTs: now(),
    })
    .then(() =>
      notifyAndTrack.success("Success", "Training started"));
  };

  if (loadingUserInfo) {
    return <Loading/>;
  }

  return <Wrapper isMobile={isMobile} height={height}>
    <DashboardRow>
      <div style={{width: "100%", maxWidth: 600}}>
        <div className="space space-col">
          <Typography.Title>Begin Training</Typography.Title>
          <Typography.Text>
            This will start training the AI Brain for your business.
          </Typography.Text>
          <br/>
          <Button
            size="large"
            type="primary"
            disabled={!!userInfo?.initialLoadTs}
            onClick={startTraining}
          >
            <h4>Start</h4>
          </Button>
          <div style={{width: "100%", paddingTop: "10px"}}>
            <Divider/>
            {!!userInfo?.initialLoadTs &&
              <Button disabled={!userInfo?.initialLoadTs} size="large" onClick={() => props.markCompleteAndNavigateNext(props.name)}>
                <h4 className="space space-center-y green-color">Continue <RightArrowOutlined/></h4>
            </Button>}
          </div>
        </div>
      </div>
    </DashboardRow>
  </Wrapper>;
};

export const Features = (props) => {
  const [isMobile, , height] = useContext(WindowContext);
  // const answersUrl = new URL("https://hulu.com/");
  // const todosUrl = new URL("https://hulu.com/");
  // const remembersUrl = new URL("https://hulu.com/");
  return <Wrapper isMobile={isMobile} height={height}>
    <DashboardRow>
      <div style={{width: "100%", maxWidth: 600}}>
        <div className="space space-col">
          <Typography.Title>Your office manager</Typography.Title>
          <Typography.Text>
            {/*<Markdown*/}
            {/*  markdown={`who never sleeps, [answers your questions](#video-link?url=${encodeURIComponent(answersUrl.toString())}), consistently performs the [tasks you assign](#video-link?url=${encodeURIComponent(todosUrl.toString())}), simplifies employee [training](#video-link?url=${encodeURIComponent(answersUrl.toString())}), and [remembers](#video-link?url=${encodeURIComponent(remembersUrl.toString())}) all those important details that keep your business and team on track.`}/>*/}
          </Typography.Text>
        </div>
      </div>
    </DashboardRow>
  </Wrapper>;
};

export const Summary = (props) => {
  const [, setLocation] = useLocation();
  const [isMobile, , height] = useContext(WindowContext);
  const [, user] = useContext(AppContext);

  React.useEffect(() => {
    props.setDisableNavigation(true);
  }, [user]);

  return <Wrapper isMobile={isMobile} height={height}>
    <DashboardRow>
      <div style={{width: "100%", maxWidth: 600}}>
        <div className="space space-col">
          <Typography.Title level={2} className="green-icon"><div className="space space-center-y"><FeatherIcon size={30} className="green-icon" /> Training Success</div></Typography.Title>
          <Progress percent={100} status="success" showInfo />
          <Divider />
          <Card className="card-rounded card-simple" extra={<Button className="green-color" onClick={() => setLocation("/today")}>Enter</Button>}>
            <Typography.Text>Hi <span className="capitalize">{user.displayName?.split(" ")?.[0] || ""}</span>, training and evaluation completed successfully. A representative will contact you to schedule your walkthrough of the app, discuss it's features, and answer any questions you may have about the Genesis Beta.</Typography.Text>
          </Card>
        </div>
      </div>
    </DashboardRow>
  </Wrapper>;
};
