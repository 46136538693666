import contentActions from "../content/actions";
const actions = {
  AI_WRITE_STREAM: "AI_WRITE_STREAM",
  AI_CLEAR_STREAM: "AI_CLEAR_STREAM",
  SET_ACTIVE_EMAIL_DRAFT: "SET_ACTIVE_EMAIL_DRAFT",
  CLEAR_ACTIVE_EMAIL_DRAFT: "CLEAR_ACTIVE_EMAIL_DRAFT",
  SET_ACTIVE_USER_TASK: "SET_ACTIVE_USER_TASK",
  CLEAR_ACTIVE_USER_TASK: "CLEAR_ACTIVE_USER_TASK",
  clearStream: (chatId) =>
    ({type: actions.AI_CLEAR_STREAM, chatId}),
  /**
   * @function setEmailDraft
   * @param {string} draftPath
   * @returns {{draftPath:string}}
   */
  setActiveEmailDraft: (draftPath) => {
    return {
      type: actions.SET_ACTIVE_EMAIL_DRAFT,
      draftPath,
    };
  },
  clearActiveEmailDraft: () => {
    return {type: actions.CLEAR_ACTIVE_EMAIL_DRAFT};
  },
  setActiveUserTask: (path) => {
    return (dispatch, state) => {
      // console.log("actions:setActiveUserTask:", path);
      if (!path) {
        throw new Error("Missing task path");
      }
      const activeUserTask =
        state().AI.userTask;

      console.log("actions:setActiveUserTask:activeUserTask:", activeUserTask, path);

      if (activeUserTask?.path === path) {
        return;
      }

      dispatch({
        type: actions.SET_ACTIVE_USER_TASK,
        path,
      });
      // dispatch(contentActions.syncItem(path));
    };
  },
  clearActiveUserTask: () => {
    return (dispatch, state) => {
      console.log("actions:clearActiveUserTask");

      const activeUserTask =
        state().AI.userTask;

      if (!activeUserTask?.path) {
        return;
      }

      dispatch({
        type: actions.CLEAR_ACTIVE_USER_TASK,
      });
      // dispatch(contentActions.unSyncItem(activeUserTask.path));
    }
  }
};

export default actions;
