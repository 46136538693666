import React from 'react';
import {Typography} from 'antd'
import Markdown from "./Markdown";

const AiChatAlert = ({text, type = "secondary"}) => {
  return <section className="sub-message">
    <Markdown markdown={text} />
  </section>
}

export default AiChatAlert;
