import React from 'react';
import PropTypes from 'prop-types';
import useToolResult from "@web/hooks/useToolResult";
import {Tag} from "antd";

ChatResponseToolResult.propTypes = {
  toolResult: PropTypes.object.isRequired,
  lazy: PropTypes.bool,
  path: PropTypes.string.isRequired,
  answerIndex: PropTypes.number.isRequired,
};

function ChatResponseToolResult(props) {
  const [lazy, setLazy] =
    React.useState(props.lazy);

  const components =
    useToolResult(
      props.toolResult,
      props.path,
      lazy,
      props.answerIndex,
    );

  if (components === null) {
    return null;
  }

  if (components?.length === 0) {
    return <div
      className="fat-chip"
      onClick={() => setLazy(false)}
    >
      <div className="bolded">
        Show related content
      </div>
    </div>
  }
  return <div className="space space-col">
    {components}
  </div>
}

export default ChatResponseToolResult;
