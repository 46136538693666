import React from 'react'
import {Avatar, Space} from "antd";
import ContentActions from './ContentActions'
import AiChatAnswer from './AiChatAnswer'
import MarkdownTimeline from "./MarkdownTimeline";
import {FeatherIcon} from "@web/assets/icons";
import PropTypes from "prop-types";

ChatResponseStream.propTypes = {
  path: PropTypes.string.isRequired,
  chat: PropTypes.object.isRequired,
  stream: PropTypes.object,
}

export default function ChatResponseStream(props) {
  return <li className="brainstorm-message left">
    <Space direction="vertical">
      <div className="sub-message-group-wrapper">
        {props.stream.answers?.map((answer, answerIndex) => {
          return <ContentActions
            path={props.path}
            text={answer.text}
            like={answer.like}
            answerIndex={answerIndex}
            key={answerIndex}
          >
            <AiChatAnswer
              chatIndex={props.chatIndex}
              answerIndex={answerIndex}
              text={answer.text}
              path={props.path}
              // widget={answer.widget}
              toolCalls={answer.toolCalls}
              toolResults={props.chat.answers[answerIndex].toolResults}
            />
          </ContentActions>;
        })}
      </div>
      {props.chat.progressTs &&
        (props.chat.progressTs > (props.chat.updatedTs || 0)) &&
        <article className="sub-message">
          <div className="space">
            <Avatar size="large" style={{visibility: "hidden"}} className="chat-avatar ai-avatar" icon={<FeatherIcon className="ai-text"/>}/>
            <MarkdownTimeline
              markdown={props.chat.progress}
              path={props.path}
              active
            />
          </div>
        </article>
      }
      <br/>
      <br/>
    </Space>
  </li>
}
