import React from "react";
import PropTypes from "prop-types";
import {Typography} from "antd";

Toggle.propTypes = {
  defaultExpanded: PropTypes.bool,
  expand: PropTypes.bool,
  trigger: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  rows: PropTypes.number,
};

function Toggle({defaultExpanded = false, trigger, rows, expand, children}) {
  const [expanded, setExpanded] = React
  .useState(defaultExpanded);

  React.useEffect(() => {
    typeof expand === 'boolean' &&
    setExpanded(expand);
  }, [expand]);

  const onClickTrigger = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setExpanded(!expanded);
    return false;
  }
  return <div className={"toggle-wrapper space space-col " + (expanded ? "expanded" : "collapsed")}>
    {!rows && <span onClick={onClickTrigger}>
      {trigger}
    </span>}
    {rows ?
      expanded ?
        <Typography.Paragraph>{children}</Typography.Paragraph> :
        <Typography.Paragraph
          onClick={onClickTrigger}
          ellipsis={{rows}}
        >{children}
        </Typography.Paragraph> :
      expanded && children
    }</div>
}

export default Toggle;
