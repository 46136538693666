import React from 'react';
import PropTypes from 'prop-types';
import useDocumentFirestore from "@web/hooks/useDocumentFirestore";
import ChatWaitForWidget from "@ai-antd/components/ChatWaitForWidget";
import {RightArrowOutlined} from "@web/assets/icons";
import {Divider} from "antd";
import ChatResponseToolResult from "@ai-antd/components/ChatResponseToolResult";
import ChartManager from "./ChartManager";

ChatProgressData.propTypes = {
  path: PropTypes.string.isRequired,
};

function ChatProgressData(props) {
  const [chat] =
    useDocumentFirestore(props.path);

  if (!chat) {
    return null;
  }

  // console.log("ChatProgressData:chatPath:answers:", props.path, chat.answers);

  return <React.Fragment>
    {!!chat.answers?.length &&
      chat.answers.map((answer) =>
        !answer.toolCalls &&
        !!answer.toolResults?.length &&
        answer.toolResults.map((toolResult) => {
          return <div
            style={{marginTop: "10px"}}
            key={toolResult.id}
          >
            {answer.agentId !== "ai-analyst" &&
              <div
                className="space space-col"
                data-tool={toolResult.id}
              >
                <ChatResponseToolResult
                  path={props.path}
                  answerIndex={props.answerIndex}
                  toolResult={toolResult}
                  lazy
                />
              </div>}
            {answer.agentId === "ai-analyst" &&
              <ChartManager
                charts={props.charts}
                answerIndex={props.answerIndex}
                path={props.path}
                lazy={false}
              />}
          </div>
        }))
    }
    {chat.widget &&
      <ChatWaitForWidget
        widget={chat.widget}
        path={props.path}
      />
    }
    {chat.progress &&
      <React.Fragment>
        <Divider className="small-top small-bottom" />
        <div className="space space-center-y">
          <RightArrowOutlined
            className="gold-icon"
            style={{minWidth: "20px"}}
            size={24}
          />
          <span className="ai-text font-lg">
            {chat.progress}
          </span>
        </div>
      </React.Fragment>
    }
  </React.Fragment>;
}

export default ChatProgressData;
