import React from "react";
import PropTypes from "prop-types";
import {Button, Card, Tag, Typography} from "antd";
import StatusActions from "./StatusActions";
import Day from "./Day";
import {AttachmentIcon, EditIcon, ReminderIcon} from "@web/assets/icons";

ReminderCard.propTypes = {
  data: PropTypes.object,
  path: PropTypes.string,
};

function ReminderCard(props) {
  if (!props.data) {
    return null;
  }

  return <Card
    title={<h6>Reminder</h6>}
    className="card-rounded card-simple ant-card-body"
    // bordered={false}
    extra={<h6 className="space space-center-y">
      <ReminderIcon size={18} />
      <Day ts={new Date(props.data.remindOnDateTime).getTime()} format="h:mm a MMM D, YYYY" />
    </h6>}
    hoverable
  >
    <div style={{position: 'relative'}} className="space space-col space-between">
      <Typography.Paragraph className="ai-text font-lg">
        {props.data.description}
      </Typography.Paragraph>
      <h6 style={{position: "absolute", bottom: "-26px", left: "0", right: "0", paddingTop: "6px"}}>
        <div className="space space-center-y space-between">
          <div className="space space-center-y">
            <Button ghost className="icon-left"><EditIcon className="green-icon" /></Button>
            <Button ghost className="icon-left"><AttachmentIcon size={16} className="green-icon" /></Button>
          </div>
          <StatusActions
            path={props.path}
            // initialStatus={todo?.status}
            iconClassName="green-icon"
            actions={['delete']}
          />
        </div>
      </h6>
    </div>
  </Card>
}

export default ReminderCard;
