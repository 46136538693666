import React from "react";
import {Col, Row} from "antd";
import PropTypes from "prop-types";

DashboardFullRow.propTypes = {
  children: PropTypes.node,
  max: PropTypes.number,
};

function DashboardFullRow({max = 24, children}) {
  return <Row className="w-full">
    {/*<Col xs={0} sm={0} md={0} lg={0} xl={3} xxl={3}>{props.left || null}</Col>*/}
    <Col xs={max} sm={max} md={max} lg={max} xl={max} xxl={max} className="overscroll-contain">
      {children}
    </Col>
  </Row>
}

export default DashboardFullRow;
