import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";

ChatItemSuggestionsWrapper.propTypes = {
  agentId: PropTypes.string.isRequired,
  // collectionPath: PropTypes.string.isRequired,
  pageSize: PropTypes.number,
  limit: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  component: PropTypes.elementType.isRequired,
};

function ChatItemSuggestionsWrapper(props) {
  const activeItem =
    useSelector((state) =>
      state.App[state.App?.activeDetailServiceId]);

  return React.createElement(props.component, {
    prompts: activeItem?.props?.data?.questions
    ?.slice(0, props.limit)
    ?.map(({question}) =>
      ({text: question})),
    agentId: props.agentId,
    // collectionPath: props.collectionPath,
    pageSize: props.pageSize,
    onClick: props.onClick,
    title: props.title,
  });
}

export default ChatItemSuggestionsWrapper;
