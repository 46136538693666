import React from "react";
import PropTypes from "prop-types";
import useDocumentFirestore from "@web/hooks/useDocumentFirestore";
import {deletePath, updatePath, Fields} from "@web/lib/firestore.db";
import aiActions from "@web/redux/ai/actions";
import {useDispatch} from "react-redux";
import useChatStreamListener from "@web/hooks/useChatStreamListener";
import UserTaskProgress from "./UserTaskProgress";
import useChatStream from "@web/hooks/useChatStream";
import now from "lodash/now";

UserTaskProgressManager.propTypes = {
  agentId: PropTypes.string,
  path: PropTypes.string,
  collapsed: PropTypes.bool,
  onAddContextPath: PropTypes.func,
  onRemoveContextPath: PropTypes.func,
};

function UserTaskProgressManager(props) {
  const {path} = props;

  const dispatch =
    useDispatch();

  const [chat, chatPath] =
    useChatStreamListener(props.agentId);

  const [, sendRequest] =
    useChatStream(props.agentId, true);

  const [isPending, startTransition] = React
  .useTransition();

  const [sending, setSending] =
    React.useState(false);

  const [sendError, setSendError] =
    React.useState(null);

  const [finished, setFinished] =
    React.useState(false);

  const [chatStartTs, setChatStartTs] = React
  .useState(null);

  const [
    userTask,
    loadingUserTask,
    error,
    snap,
  ] = useDocumentFirestore(path);

  // console.log("UserTaskProgressManager:userTask:", userTask, loadingUserTask, error);

  const [agentAnswer, setAgentAnswer] = React
  .useState(null);

  const [collapsed, setCollapsed] = React
  .useState(props.collapsed);

  // const addEmail = React
  // .useCallback((email) => {
  //   console.log("addEmail:", email);
  //   const alreadyInDistro = draft
  //   .toEmails
  //   .some((member) =>
  //     member.email === email);
  //
  //   if (alreadyInDistro) {
  //     return;
  //   }
  //
  //   return updatePath(draftPath,{
  //     ...draft,
  //     toEmails: [
  //       ...draft.toEmails,
  //       {email, name: email},
  //     ],
  //   });
  // }, [draft]);

  // const updateSendAction = React
  // .useCallback((newAction) => {
  //   console.log("updateSendAction:", newAction);
  //   return updatePath(draftPath, {
  //     ...draft,
  //     action: newAction,
  //   });
  // }, [draft]);

  // const save = React
  // .useCallback(() => {
  //   console.log("Saving draft", draftPath);
  //   return setPath(
  //     props.draftPath, {
  //       ...draft,
  //       message: text,
  //       updatedTs: now(),
  //     });
  // }, [draft]);

  // const send = React
  // .useCallback(() => {
  //   console.log("Sending email", draftPath);
  //     setSending(true);
  //     return setPath(
  //       props.draftPath, {
  //         ...draft,
  //         message: text,
  //         updatedTs: now(),
  //         submitTs: now(),
  //       },
  //     )
  //     .then(() =>
  //       api.POST(
  //         "http://127.0.0.1:5001/admin-85663/us-west1/user-sendEmail",
  //         {path: draftPath}),
  //     )
  //     .then((res) => {
  //       console.log("Response", res);
  //       if (res.error) {
  //         setSending(false);
  //         setSendError(res);
  //         return;
  //       }
  //       notification
  //       .success({
  //         message: "Email sent",
  //         description: "Your email has been sent successfully",
  //       });
  //       setFinished(true);
  //
  //       aiActions
  //       .clearActiveEmailDraft();
  //     })
  //     .catch((err) => {
  //       setSending(false);
  //       notification
  //       .error({
  //         message: "An error occurred",
  //         description: "We are looking into it",
  //       });
  //     });
  // },[draft, draftPath]);

  const collapse = React
  .useCallback(() => {
    console.log("Collapsed:", collapsed);
      setCollapsed(!collapsed);
    }, [collapsed]);

  const deleteTask = React
  .useCallback(() => {
    console.log("Deleting task:", path);
    return deletePath(path)
    .then(() => setFinished(true));
  }, [path]);

  const addItemToTask = React
  .useCallback((itemPath) => {
    console.log("addItemToTask:", itemPath);
    return path &&
      updatePath(path, {
        itemPaths: Fields.arrayUnion(itemPath),
      });
  }, [path]);

  React
  .useEffect(() => {
    finished ?
      props.onRemoveContextPath(path) :
      props.onAddContextPath(path);
  }, [finished]);

  // React
  // .useEffect(() => {
  //   if (!chat) {
  //     return;
  //   }
  //   console.log("UserTaskProgressManager:chat:", chat.answers);
  //   const userTaskAnswer = chat
  //   .answers
  //   .find((answer) =>
  //     chatAppAgents
  //     .userTaskProgress
  //     .agentIds
  //     .includes(answer.agentId),
  //   );
  //
  //   userTaskAnswer && // todo: Use startTransition() for performance if needed
  //   setAgentAnswer(userTaskAnswer);
  // }, [chat, chatPath]);

  React
  .useEffect(() => {
    if (userTask && !chatStartTs) {
      setChatStartTs(now());
      console.log(">>>>>>>>>TaskManager:sendRequest:", props.agentId, path);
      return sendRequest(
        userTask.actions?.length ?
          "lets continue" :
          "Lets work on this task",
        [path, ...userTask.action?.map((action) => action.draftPath) || []],
        0,
        path + `/${props.agentId}`,
      );
    }
  }, [userTask, chatStartTs]);

  React
  .useEffect(() => {
    !loadingUserTask &&
    !userTask &&
    console.log("TaskManager:useEffect:setFinished:true");

    !loadingUserTask &&
    !userTask &&
    setFinished(true);
  }, [loadingUserTask, userTask]);

  React.useEffect(() => {
    finished &&
    dispatch(
      aiActions
      .clearActiveUserTask(),
    );
  }, [finished]);

  const clearActiveEmailDraft = React
  .useCallback(() => {
    console.log("userTaskProgressManager:clearActiveEmailDraft");
    // dispatch(
    //   aiActions
    //   .clearActiveEmailDraft(),
    // );
  }, []);

  if (loadingUserTask) {
    return null;
  }

  return <UserTaskProgress
    collapsed={!!props.collapsed}
    // agentAnswer={agentAnswer}
    userTask={userTask}
    finished={finished}
    onClickCollapse={collapse}
    onClickDelete={deleteTask}
    onAddContextPath={props.onAddContextPath}
    onRemoveContextPath={props.onRemoveContextPath}
    onClearEmailDraftAction={clearActiveEmailDraft}
  />;
}

export default UserTaskProgressManager;
