import React from "react";
import {AudioIcon, AudioIconFilled} from "@web/assets/icons";
import {useDispatch, useSelector} from "react-redux";
import {Button} from "antd";
import voiceActions from "@web/redux/background/actions";

function VoiceMuteButton() {
  const dispatch = useDispatch();
  const isMuted = useSelector(state =>
    state.Voice.muted);

  const toggleMute = () => dispatch(voiceActions.toggleMute());

  return <Button className="icon-btn icon-right" ghost onClick={toggleMute}>{
    isMuted ?
      <AudioIcon size={20} /> :
      <AudioIconFilled size={20} />}
  </Button>
}

export default VoiceMuteButton;
