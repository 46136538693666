import React from 'react';
import PropTypes from 'prop-types';
import {Button, Card, Divider, Tag, Tooltip} from "antd";
import {RepeatIcon, XIcon} from "@web/assets/icons";

ScribeWorkflowPrompt.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
};

function ScribeWorkflowPrompt(props) {
  if (!props.text) {
    return null;
  }
  return <Tag
    closable={false}
    className="font-lg"
    style={{
      borderRadius: '26px',
      padding: '15px 42px 15px 30px',
      marginBottom: '0',
      border: 'none',
      backgroundColor: '#111b26'
    }}
    onClick={() =>
      props.onClick &&
      props.onClick(props.text)
    }>
    {props.text}
  </Tag>;
}

export default ScribeWorkflowPrompt;
