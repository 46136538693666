import React from 'react';
import {Card, Button, Image} from 'antd';
import PropTypes from 'prop-types';
import {DownArrowOutlined, DownIcon, GlobeIcon} from "@web/assets/icons";

GoogleSearch.propTypes = {
  data: PropTypes.object,
};

function GoogleSearch(props) {
  const [collapsed, setCollapsed] = React
  .useState(props.collapsed ?? true);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  }

  if (!props.data) {
    return <div className="fat-chip">Internet Agent</div>;
  }

  /** @type {models.GoogleSearch.Data} */
  const data = props.data;

  console.log("GoogleSearch:data:", data);
  const isImageSearch = data.queries.request[0].searchType === "image";

  return <Card
    title={<div className="space space-center-y">
      <GlobeIcon />
      <span className="bolded font-lg underline">
        {data.queries.request[0].searchTerms}
      </span>
    </div>}
    bordered={false}
    className="card-simple card-rounded"
    extra={<Button ghost onClick={toggleCollapse}><DownArrowOutlined /></Button>}
    style={{marginTop: 10, marginBottom: 10, width: '100%', maxWidth: '1000px'}}
    actions={!collapsed && [
      <Button key="more">More</Button>,
      <Button>Save Images</Button>,
      <Button>Save Search</Button>
    ]}
  >
    {!collapsed &&
      <div className="space space-col">
        {data.items.map((item, index) => {
          return isImageSearch ?
            <div key={index} style={{marginBottom: '10px'}}>
              <Image src={item.link} alt={item.title} style={{width: '100%'}}/>
            </div> :
            <div key={index} style={{marginBottom: '10px'}} className="space space-center-y">
              <img src={item.pagemap.cse_image[0].src} alt={item.title} style={{width: '25%'}}/>
              <div style={{width: '74%', boxSizing: 'border-box', paddingLeft: 10}}>
                <a href={item.link} target="_blank" rel="noreferrer">
                  <b>{item.title}</b>
                </a>
                <p>{item.snippet}</p>
              </div>
            </div>
        })}
      </div>
    }
  </Card>
}

export default GoogleSearch;
