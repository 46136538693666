import React from 'react';
import {useWavesurfer} from '@wavesurfer/react'
import PropTypes from 'prop-types';
import {PauseIcon, PlayIcon} from "@web/assets/icons";
import useDocumentFirestore from "@web/hooks/useDocumentFirestore";
import {Button, List} from "antd";
// import {Button} from "antd";

AudioPlayer.propTypes = {
  src: PropTypes.string,
  transcriptPath: PropTypes.string,
  mimeType: PropTypes.string,
};

function AudioPlayer(props) {
  console.log("AudioPlayer:props", props.src, props.mimeType);
  const containerRef = React.useRef(null);
  const audioRef = React.useRef(null);
  const [audioTranscript] = useDocumentFirestore(props.transcriptPath);
  const {wavesurfer, isReady, isPlaying, currentTime } = useWavesurfer({
    container: containerRef,
    url: props.src,
    waveColor: 'tan',
    progressColor: 'grey',
    height: 60,
    media: audioRef.current,
  });

  if (!props.src) {
    return null;
  }
  return <React.Fragment>
    <div style={{width: '80%', margin: '0 auto'}} ref={containerRef}/>
    <audio
      controls
      autoPlay={false}
      muted={false}
      ref={audioRef}
      style={{
        width: '100%',
        accentColor: 'tan',
      }}
    >
      <source src={props.src} type={props.mimeType}/>
      {/*<source src="horse.mp3" type="audio/mpeg"/>*/}
      Your browser does not support the audio element.
    </audio>
    <br/>
    <List
      dataSource={Object.values(audioTranscript?.results || {})[0]?.transcripts}
      renderItem={(item) =>
        <List.Item>
          <div style={{width: '80%', margin: 'auto', paddingTop: '30px', paddingBottom: '30px'}}>
            <span className="font-lg ai-text">{item.text}</span>
          </div>
        </List.Item>}
      locale={{
        emptyText: props.transcriptPath ?
          "Processing transcript" :
          <Button onClick={() => null} className="green-color">Transcribe</Button>
      }}
    />
    {/*<Button*/}
    {/*  ghost*/}
    {/*  onClick={onPlayPause}*/}
    {/*>*/}
    {/*  {isPlaying ?*/}
    {/*    <PauseIcon className="green-icon"/> :*/}
    {/*    <PlayIcon className="green-icon"/>*/}
    {/*  }*/}
    {/*</Button>*/}
    {/*<span className="font-sm uppercase">{currentTime} {isReady ? "ready" : "loading"}</span>*/}
  </React.Fragment>;
}

export default AudioPlayer;
