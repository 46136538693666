import React from 'react';
import PropTypes from 'prop-types';
import {getDownloadUrl} from "@web/lib/cloud.storage";

StorageFile.propTypes = {
  storageURI: PropTypes.string.isRequired,
};

function StorageFile({storageURI, children, ...props}) {
  const [src, setFileUrl] =
    React.useState("");

  React.useEffect(() => {
    getDownloadUrl(storageURI)
      .then((url) => setFileUrl(url))
  }, [storageURI]);

  return React.cloneElement(
    React.Children.only(children),
    {src},
  )
}

const MemoizedComponent = React
.memo(
  StorageFile,
  (prevProps, nextProps) => {
    return prevProps.storageURI === nextProps.storageURI;
  });

export default MemoizedComponent;
