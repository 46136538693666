import React, {useContext} from "react";
import {auth} from "@web/lib/firebase";
import {WindowContext} from '@web/hooks/context';
import {useAuthOven} from "@web/hooks/useAuthOven";
import Loading from "../Loading";
import {buildQuery, setPath} from "@web/lib/firestore.db";
import useQueryFirestore from "@web/hooks/useQueryFirestore";
import OnboardingRouter from "./OnboardingRouter";
import now from "lodash/now";

export default function OnboardingData() {
  const steps = [
    "welcome",
    "origin",
    "connect",
    "tools",
    // linkedAccounts: LinkedAccounts,
    "websites",
    // "voice",
    // "features",
    // "terms",
    // invites: Invites,
    "training",
    "summary",
    "team",
  ];

  const [isMobile] = useContext(WindowContext);
  const [user, claims, loadingUser] =
    useAuthOven(auth);

  const [
    completedSteps,
    loadingOnboarding,
    errorLoadingOnboarding,
  ] = useQueryFirestore(buildQuery(["user", user.uid, "onboarding", "beta", "boards"], {}));

  function markComplete(stepName) {
    setPath(["user", user.uid, "onboarding", "beta", "boards", stepName],
      {completed: true, date: now()},
      true)
    .then(() =>
      console.log("completed:", stepName));
  }

  const completedStepNames =
    completedSteps.map((step) => step[0]);

  const initialIndex =
    steps.findIndex((stepName) =>
      !completedStepNames.includes(stepName));

  if (loadingUser) {
    return <Loading/>
  }

  if (loadingOnboarding) {
    return <Loading/>
  }

  return <OnboardingRouter
    initialStepIndex={initialIndex}
    stepNames={steps}
    completedNames={completedStepNames}
    isMobile={isMobile}
    onCompleteStep={markComplete}
  />
}
