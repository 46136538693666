import React from 'react';
import {Button, Card, Divider, Typography} from "antd";
import {LineChartIcon} from "@web/assets/icons";
import {api} from "@web/lib/api";
import {AppContext} from "@web/hooks/context";
import PropTypes from "prop-types";

const chartComponents = {
  "area": React.lazy(() => import("@ai-antd/components/charts/AreaChart")),
  "bar": React.lazy(() => import("@ai-antd/components/charts/BarChart")),
  "box": React.lazy(() => import("@ai-antd/components/charts/BoxChart")),
  "column": React.lazy(() => import("@ai-antd/components/charts/BarChart")),
  "custom": React.lazy(() => import("@ai-antd/components/charts/CustomChart")),
  "heatmap": React.lazy(() => import("@ai-antd/components/charts/HeatmapChart")),
  "line": React.lazy(() => import("@ai-antd/components/charts/LineChart")),
  "pie": React.lazy(() => import("@ai-antd/components/charts/PieChart")),
  "polar": React.lazy(() => import("@ai-antd/components/charts/PolarChart")),
  "radar": React.lazy(() => import("@ai-antd/components/charts/RadarChart")),
  "table": React.lazy(() => import("@ai-antd/components/charts/TableChart")),
}

ChartManager.propTypes = {
  charts: PropTypes.object,
  answerIndex: PropTypes.number,
  path: PropTypes.string,
  lazy: PropTypes.bool,
}
// const blockedPaths = [
//   // 'user/roHpDlqYSeRAy0xfV8sjgVsFLG33/managers/ai-analyst/ai-workflow/O54wydsIDzq8UYJ9GwJv',
//   'user/roHpDlqYSeRAy0xfV8sjgVsFLG33/managers/ai-analyst/ai-workflow/b80pqPUazFjAqqKFEes2',
//   // 'user/roHpDlqYSeRAy0xfV8sjgVsFLG33/managers/ai-analyst/ai-workflow/fVMLn07tg9e494jgQbxa',
//   // 'user/roHpDlqYSeRAy0xfV8sjgVsFLG33/managers/ai-analyst/ai-workflow/aswJ8JYUga8gmrK4EOQH',
// ]
function ChartManager(props) {
  const [_, user] = React.useContext(AppContext);
  const generateChart = (path, answerIndex) => {
    api.POST("http://127.0.0.1:5001/admin-85663/us-west1/user-generateChart", {
      data: {userId: user.uid, path, answerIndex},
    });
  }

  return <Card
    style={{marginTop: '10px', marginBottom: '20px'}}
    title={<Typography.Text className="capitalize muted">{!!props.charts && Object.values(props.charts)[0].type}</Typography.Text>}
    className="card-simple card-rounded card-no-header card-rounded extra-round"
    extra={
    <Button
      ghost
      onClick={() => generateChart(props.path, props.answerIndex)}>
      <LineChartIcon />
    </Button>
    }>
    {!!props.charts &&
      Object.entries(props.charts)
      .reduce(($acc, [chartId, chart], index, list) => {
        $acc
        .push(React
        .createElement(chartComponents[chart.type], {
          key: chartId,
          chartId,
          ...chart,
          lazy: props.lazy,
        }));
        (list.length > (index + 1)) &&
        $acc.push(<Divider />);
        return $acc;
      }, [])
    }
  </Card>
}

export default ChartManager;
