import React, {useCallback, useContext} from "react";
import {useDispatch, useSelector} from "react-redux";
import appActions from "@web/redux/app/actions";
import {SourcesContext, AppContext, WindowContext} from "@web/hooks/context";
import DashboardFullRow from "@ai-antd/components/DashboardFullRow";
import ScribeWorkflow from "@ai-antd/components/ScribeWorkflow";
import PropTypes from "prop-types";

ChatPage.propTypes = {
  asOverlay: PropTypes.bool,
  children: PropTypes.node,
};

function ChatPage(props) {
  // useHotkeys('space', (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   dispatch(appActions.toggleChatMode())
  // }, {});
  //
  // useHotkeys('esc', (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   dispatch(appActions.exitChatMode())
  // }, {});

  const dispatch = useDispatch();
  const [_, user] = useContext(AppContext);
  const [,,height] = useContext(WindowContext);
  const sources = useContext(SourcesContext);
  // const activeSolutionId = useSelector((state) => state.App.activeSolutionId);
  const activeDetailServiceId =
    useSelector((state) =>
      state.App.activeDetailServiceId || state.App.activeServiceId);

  const activeUserTask =
    useSelector((state) =>
      state.AI.userTask);

  const activeItem = useSelector((state) =>
    state.App[state.App.activeDetailServiceId]);

  const [agentId, setAgentId] =
    React.useState(sources?.services?.[activeDetailServiceId]?.chat?.agentId || "ai-assistant");

  const [isTextboxFocused, setIsTextboxFocused] =
    React.useState(false);

  const exitChatMode =
    React.useCallback(() =>
      dispatch(appActions.exitChatMode()),
      [dispatch]);

  const onFocus = useCallback((focus) => {
    setIsTextboxFocused(focus);
  }, []);

  React.useEffect(() => {
    activeUserTask ?
      setAgentId(sources?.services?.progress?.chat?.agentId) :
      setAgentId(sources?.services?.[activeDetailServiceId]?.chat?.agentId || "ai-assistant");
  }, [
    activeDetailServiceId,
    activeUserTask,
  ]);

  const pills = [
    "what can you help with?",
  ];

  if (!user?.uid) {
    return null;
  }

  return <DashboardFullRow>
    <div className={`chat-page-wrapper ${props.asOverlay ? "as-overlay" : "as-page"}`}>
      <section
        id="dashboard-detail-wrapper"
        style={{height: height - 200, overflowY: 'auto'}}
      >
        <div style={{width: '100%', margin: '0 auto'}}>
          {props.children}
        </div>
      </section>
      <div id="dashboard-chat-wrapper" style={{position: 'absolute', bottom: 20, left: 0, right: 0, width: '100%'}}>
        <div className="space space-col bottom center" style={{width: '100%', height: '100%'}}>
          <div style={{width: "100%", maxWidth: '1000px'}}>
            <ScribeWorkflow
              agentId={agentId}
              primary={true}
              placeholder=""
              collectionPath={
                sources.services[activeDetailServiceId]?.chat?.getCollectionPath(user, agentId, activeItem?.props)
              }
              activeItemPaths={
                [activeItem?.props?.path]
                .filter(Boolean)
              }
              input={{pills}}
              hideActionTags={false}
              collapsed={false}
              onFocus={onFocus}
              onClose={exitChatMode}
              showThread={true}
            />
          </div>
          <div className="app-chat-action-bar animate"/>
        </div>
      </div>
    </div>
  </DashboardFullRow>
}

export default ChatPage;
