import React, {useContext} from "react";
import {Avatar} from "antd";
import HotClick from "@web/ui/containers/HotClick";
import MarkdownTimeline from "./MarkdownTimeline";
import {FeatherIcon} from "@web/assets/icons";
import {WindowContext} from "@web/hooks/context";
import PropTypes from "prop-types";
import ChatResponseToolResult from "./ChatResponseToolResult";
import ChartManager from "@web/ui/containers/ChartManager";

// const AiAnswerWithCode = React.lazy(() => import("./AiAnswerWithCode"));

AiChatAnswer.propTypes = {
  agentId: PropTypes.string,
  answerIndex: PropTypes.number.isRequired,
  chatIndex: PropTypes.number,
  path: PropTypes.string.isRequired,
  text: PropTypes.string,
  widget: PropTypes.object,
  toolCalls: PropTypes.array,
  toolResults: PropTypes.array,
  charts: PropTypes.object,
};

export default function AiChatAnswer(props) {
  const [, width] = useContext(WindowContext);
  // console.log("AiChatAnswer", props.path, props.answerIndex);

  return <article className="sub-message" data-agentId={props.agentId}>
    {/*<AnswerActionHeader chatId={chatId} answer={answer} />*/}
    {/*<AiAgentHandle agentId={answer.agentId} />*/}
    <div className="space">
      {/*<Typography.Text className="ai-text">{props.chatIndex}</Typography.Text>*/}
      <Avatar
        size="large"
        style={{visibility: "hidden"}}
        className="chat-avatar ai-avatar"
        icon={<FeatherIcon className="ai-text"/>}
      />
      {/*{props.text?.indexOf("`") > -1 &&*/}
      {/*  <React.Suspense fallback={<Loading/>}>*/}
      {/*    <AiAnswerWithCode text={props.text}/>*/}
      {/*  </React.Suspense>*/}
      {/*    }*/}
      <div style={{maxWidth: width - 45}}>
        {!!props.text &&
          <HotClick className="relative" data={{path: props.path}}>
            <MarkdownTimeline
              markdown={props.text?.trim()}
              path={props.path}
              active={false}
            />
          </HotClick>}
        {/*{!!props.toolCalls?.length &&*/}
        {/*  <div style={{marginTop: "10px"}}>*/}
        {/*    <span className="ai-text fat-chip">*/}
        {/*      {props.toolCalls.map((toolCall) => toolCall.function?.name)}*/}
        {/*    </span>*/}
        {/*  </div>*/}
        {/*}*/}
        {!!props.toolResults?.length &&
          props.toolResults.map((toolResult) => {
            return <div style={{marginTop: "10px"}} key={toolResult.id}>
              {props.agentId !== "ai-analyst" &&
                <div className="space space-col" data-tool={toolResult.id}>
                <ChatResponseToolResult
                  path={props.path}
                  answerIndex={props.answerIndex}
                  toolResult={toolResult}
                  lazy={props.chatIndex > 0}
                />
              </div>}
              {props.agentId === "ai-analyst" &&
                <ChartManager
                  charts={props.charts}
                  answerIndex={props.answerIndex}
                  path={props.path}
                  lazy={false}
                />}
            </div>
          })
        }
        {/*{!!props.widget &&*/}
        {/*  <ChatWaitForWidget widget={props.widget} path={props.path}/>}*/}
      </div>
    </div>
  </article>
}
