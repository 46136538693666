import React, {useContext} from "react";
import {Affix, Button, Card, Divider, Empty, List} from "antd";
import {buildQuery, colls} from '@web/lib/firestore.db'
import useQueryFirestore from '@web/hooks/useQueryFirestore'
import ChatItem from './ChatItem'
import {AppContext, WindowContext} from '@web/hooks/context'
import PropTypes from "prop-types";
// import EventListItem from "./EventListItem";
// import FeedSearchItemData from "@web/ui/containers/FeedSearchItemData";
// import map from "lodash/map";
import {DownArrowOutlined, UpArrowIcon, UpChevron} from "@web/assets/icons";

ChatThread.propTypes = {
  collectionPath: PropTypes.string.isRequired,
  limit: PropTypes.number,
  orientation: PropTypes.string,
  style: PropTypes.object,
  pagination: PropTypes.object,
  filters: PropTypes.object,
  stream: PropTypes.object,
  empty: PropTypes.object,
  lazy: PropTypes.bool,
}

function ChatThread ({collectionPath, limit = 10, orientation = 'bottom', pagination, filters = {}, stream, ...props}) {
  const [, user] = useContext(AppContext);

  const [,, height,,headerHeight] =
    useContext(WindowContext);

  const [showHistory, setShowHistory] =
    React.useState(!props.lazy);

  const getQuery = () => {
    if (!showHistory) {
      return null;
    }
    return buildQuery(
      collectionPath.split('/'),
      filters,
      {
        order: [['prompt.createdTs', 'desc']],
        limit,
      });
  }

  const toggleHistory = () =>
    setShowHistory(!showHistory);

  const [pairs = [], dataLoading, error] =
    useQueryFirestore(getQuery());

  if (!user) {
    return null;
  }

  const getEmptyComponent = () => {
    return {
      emptyText: <Empty
        description={""}
        imageStyle={{display: 'none'}}
      >
        <div className="space">
          <Button
            shape="round"
            ghost
            className="icon-btn icon-left absolutes"
            onClick={toggleHistory}
            style={{marginBottom: "10px"}}
          >
            <UpArrowIcon size={20} />
            <span className="muted font-thin">Show History</span>
          </Button>
        </div>
        {props.empty}
      </Empty>
    };
  };

  const getDataSource = () => {
    const chats = pairs
    .reduce(($acc, [chatId, chat, path], chatIndex) => {
      $acc.push({
        key: chatId,
        chatIndex,
        path,
        chat,
        stream: (stream?.chatId !== chatId) ? stream : null,
      });
      return $acc;
    }, []);

    return orientation === 'top' ?
      chats :
      chats.toReversed();

    // return feed?.length ?
    //   [...chatList, ...(orientation === 'top' ? feed : feed.toReversed())] :
    //   chatList;
  }

  return <List
    id="chatThreadWrapper"
    footer={
    showHistory &&
      !!pairs?.length &&
      <Affix
        offsetTop={0}
        target={() =>
          document.getElementById("chatThreadWrapper")}
      >
        <Button
          ghost={false}
          className="fat-chip icon-btn icon-left icon-right absolute primary"
          onClick={toggleHistory}
          style={{borderBottomRightRadius: 0, borderTopRightRadius: 0, top: "-24px"}}
        >
          <DownArrowOutlined className="green-icon" />
        </Button>
      </Affix>}
    style={{width: '100%', height: height - headerHeight, ...props.style || null}}
    className={`chat-thread-wrapper ${pairs.length === 0 ? 'empty' : ''} ${orientation === 'top' ? ' orientation-top' : ' orientation-bottom'}`}
    split={false}
    dataSource={getDataSource()}
    renderItem={(props) => <ChatItem {...props} />}
    loading={{spinning: dataLoading, delay: 1000}}
    locale={getEmptyComponent()}
    pagination={pagination}
  />
}

export default ChatThread
