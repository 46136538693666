import React from 'react';
import PropTypes from 'prop-types';
import MarkdownTimeline from "./MarkdownTimeline";
import {Divider, Tooltip} from "antd";

ChatWaitForWidget.propTypes = {
  widget: PropTypes.object,
  path: PropTypes.string,
};

function ChatWaitForWidget(props) {
  const [open, setOpen] =
    React.useState(false);

  React.useEffect(() => {
    if (props.widget?.request?.tip) {
      setOpen(!!props.widget?.request?.tip?.open);
    }
  }, [props.widget?.request?.tip?.open]);

  if (!props.widget) {
    return null;
  }

  return <div style={{paddingBottom: '20px'}}>
    <Tooltip
      title={<div className="fat-chip bolded">{props.widget.request?.tip?.text}</div>}
      trigger={['hover']}
      placement="topLeft"
      open={open}
      onOpenChange={(open) => setOpen(open)}
    >
      <div className="space">
        {props.widget?.links?.map((link, index) =>
          <div style={{maxWidth: "360px"}} key={index}>
            <MarkdownTimeline
              markdown={link}
              style={{height: 'auto'}}/>
          </div>)}
      </div>
    </Tooltip>
  </div>;
}

export default ChatWaitForWidget;
