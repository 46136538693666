import React from 'react';
import {api} from "@web/lib/api";
import uniq from "lodash/uniq.js";

const serviceEndpoint = () => {
  return (process.env.NODE_ENV === "production") ?
    "https://user-saveUpload-7zujc5azra-uc.a.run.app" :
    "http://127.0.0.1:5001/admin-85663/us-west1/user-saveUpload";
}

/**
 * @summary use save uploads hook
 * @return {[Object<string, web.client.UserCreateFileResult>, function(Object<string, {gsUrl:string, originFilename:string}>): void, string[]]}
 */
function useSaveUploads() {
  const [savedUploads, setSavedUploads] = React.useState(null);
  const [submittedKeys, setSubmittedKeys] = React.useState([]);

  /**
   * @summary Save upload
   * @type {(function(Object<string, {gsUrl:string, originFilename:string}>): void)|*}
   */
  const saveUpload = React.useCallback((uploads) => {
    const newUploadsList =
      Object.entries(uploads)
      .filter(([gcsFilename, upload]) =>
        !submittedKeys.includes(gcsFilename));

    if (!newUploadsList.length) {
      return;
    }

    setSubmittedKeys(uniq([
      ...submittedKeys,
      ...Object.keys(uploads),
    ]));

    console.log("useSaveUploads:saveUpload", {submittedKeys, newUploadsList});

    api.call(
      "user-saveUpload", {
      uploads: Object.fromEntries(newUploadsList),
    })
    .then((response) => response.data?.uploads)
    .then((userCreateFileResult) =>
      !!userCreateFileResult &&
      setSavedUploads({
        ...savedUploads,
        ...userCreateFileResult,
      }))
    .catch((error) =>
      console.error("useSaveUploads:saveUpload:error:", error));
  }, [savedUploads, submittedKeys]);

  return [savedUploads, saveUpload, submittedKeys];
}

export default useSaveUploads;
