import React from 'react';
import PropTypes from 'prop-types';
import {Typography} from "antd";
import MarkdownTimeline from "./MarkdownTimeline";
import StatusActions from "./StatusActions";
import {useSelector} from "react-redux";

TimelineActivityEvent.propTypes = {
  eventId: PropTypes.string.isRequired,
  cardTitle: PropTypes.string,
  primary: PropTypes.bool,
  onClick: PropTypes.func,
};

function TimelineActivityEvent(props) {
  // /** @type {web.client.ContentItemActivity} */
  const activityEvent =
    useSelector((state) =>
      state.Content.events?.[props.eventId]);

  if (!activityEvent) {
    return null;
  }

  return <section
    onClick={(e) => {
      console.log("TimelineActivityEvent:onClick");
      props.onClick && props.onClick(e);
    }}
    className="cursor-pointer"
    // style={{border: 'solid 1px orange'}}
  >{props.primary ?
    <MarkdownTimeline
      key={activityEvent.date}
      markdown={activityEvent.summary}
      // collapsible={{rows: 4}}
      components={{
        p: (props) => {
          return <Typography.Paragraph
            className="ai-text"
            style={{fontSize: '30px', lineHeight: '1.4em', marginBottom: 0}}
            // ellipsis={{rows: 6, expandable: true}}
          >{props.children}
          </Typography.Paragraph>
        }}}
      // action={[
      //   !!activityEvent.recommendedTodo &&
      //   activityEvent.recommendedTodo.status !== "completed" &&
      //   <div
      //     key="actions-1"
      //     className="space space-col"
      //     style={{paddingLeft: '22px', marginBottom: '30px', borderLeft: 'solid 4px rgba(255,255,255,.1)'}}
      //   >
      //     {/*<label className="muted font-xl">{props.contentItemActivity.recommendedTodo.dueDateOfTodo && "By " + dayjs(props.contentItemActivity.recommendedTodo.dueDateOfTodo).format("dddd MMMM D h:mm a")}</label>*/}
      //     <label
      //       className="muted font-xl">
      //       {activityEvent.recommendedTodo.goal}
      //     </label>
      //     <Typography.Text
      //       className="ai-text"
      //       style={{fontSize: '24px', lineHeight: '1.4em', marginBottom: '15px'}}
      //     >
      //       {activityEvent.recommendedTodo.summary}
      //     </Typography.Text>
      //     <h6>
      //       <div className="space space-center-y space-between">
      //         <StatusActions
      //           path={activityEvent.activityPath}
      //           itemId={activityEvent.activityPath}
      //           initialStatus={activityEvent.recommendedTodo.status}
      //           iconClassName="green-icon"
      //           actions={['done', 'dismiss']}
      //         />
      //       </div>
      //     </h6>
      //   </div> || null]
      // }
    /> :
    <MarkdownTimeline
      markdown={activityEvent.summary}
      // action={[
      // <Button onClick={onClickItem} ghost className="icon-btn icon-left"><DownArrowOutlined /> Details</Button>,
      //   <RecommendedTodo
      //     key={props.eventId}
      //     eventId={props.eventId}
      //     activityPath={props.activityPath}
      //     collapsed
      //   />,
      // ]}
    />
  }
  </section>
}

export default TimelineActivityEvent;
