import React, {useCallback} from "react";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {Button, Card, Comment, Typography} from "antd";
import {AttachmentIcon, EmailIconOutlined, RightArrowOutlined, UpChevron} from "@web/assets/icons";
import appActions from "@web/redux/app/actions";
import {WindowContext} from "@web/hooks/context";
import UserAvatar from "./UserAvatar";
import useDomainIcon from "@web/hooks/useDomainIcon";
import Day from "./Day";
import MarkdownTimeline from "./MarkdownTimeline";

MessageCard.propTypes = {
  message: PropTypes.object,
  path: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  collapsed: PropTypes.bool,
};

function MessageCard(props) {
  const dispatch =
    useDispatch();

  const [collapsed, setCollapsed] =
    React.useState(props.collapsed);

  const emailMessage =
    useSelector((state) =>
      state.Content.data?.[props.path]);

  const message =
    props.message || emailMessage;

  const contact = useSelector((state) => {
    return message?.memberUserIds?.length &&
      state.Content.members?.[message?.memberUserIds?.[0]];
  });

  const [isMobile] = React
  .useContext(WindowContext);

  const DomainIcon =
    useDomainIcon(
      contact?.domain,
      contact?.indicators,
    );

  const setActiveDetail =
    useCallback((serviceId, props, show) => {
    dispatch(appActions.setActiveDetail(serviceId, props, show));
  }, [dispatch]);

  const showDetail = (e) => {
    if (props.onClick) {
      props.onClick(e, {path: props.path, message});
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    setActiveDetail("threads", {path: props.path, message}, true);
    return false;
  }

  if (!props.path) {
    return null;
  }

  if (!message) {
    return null;
  }

  if (collapsed) {
    return <Button
      size="large"
      type="link"
      className="icon-btn icon-left underline"
      onClick={() => setCollapsed(false)}>
        <RightArrowOutlined /> Email message
    </Button>
  }

  return <Card
    title={<div className="space space-center-y" style={{maxWidth: '85%'}}><Button ghost><EmailIconOutlined /></Button><span className="line-clamp-1 muted">{message.subject}</span></div>}
    className={`card-rounded card-simple card-slim ant-card-body card-slim-title extra-round card-no-titles `}
    bordered={false}
    hoverable
    extra={
      <Button
        ghost
        className="icon-btn icon-left underline"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setCollapsed(true);
          return false;
        }}
      >
        <UpChevron />
      </Button>
    }
    onClick={showDetail}
  >
    <div
      style={{
        position: 'relative',
        maxHeight: '400px',
        overflow: isMobile ? "hidden" : "auto", paddingBottom: '10px'
      }}
    >
      <Comment
        avatar={
          <UserAvatar
            icon={DomainIcon}
            userId={props.path.split("/").pop()}
            name={message.from?.name}
            displayName={message.from?.name || message.from?.email}
            src={contact?.photoURL}
          />
        }
        author={
          <label
            className="ant-comment-content-author-name"
          >{message.from?.email === message.owner.email ?
            message.owner.name :
            message.from?.name ||
            message.from?.email?.split("@")?.[0]}
          </label>
        }
        datetime={<Day ts={message.date} />}
        content={
          <MarkdownTimeline
            markdown={message.markdown || message.text || message.snippet}
            components={{
              p: (props) =>
                <Typography.Paragraph
                  ellipsis={{rows: 8, expandable: true}}
                  className="render-line-breaks mb-0">
                  {props.children}
                </Typography.Paragraph>
            }}
          />
        }
        actions={[
          message.attachments ?
            <div className="space space-center-y">
              <AttachmentIcon className="muted-icon" />
              <span className="muted">{Object.keys(message.attachments).length}</span>
            </div> :
            null
        ]}
      />
      {/*<div style={{*/}
      {/*  maxHeight: '400px',*/}
      {/*  overflow: isMobile ? "hidden" : "auto", paddingBottom: '10px'*/}
      {/*}}>*/}
        {/*<div style={{width: "100%"}} className="space space-col">*/}
        {/*  <UserAvatar*/}
        {/*    icon={DomainIcon}*/}
        {/*    userId={props.path.split("/").pop()}*/}
        {/*    name={message.from?.name}*/}
        {/*    displayName={message.from?.name || message.from?.email}*/}
        {/*    src={contact?.photoURL}*/}
        {/*  />*/}
        {/*  <Typography.Text className="font-xl"><span dangerouslySetInnerHTML={{__html: message.snippet || message.text || ""}}></span></Typography.Text>*/}
        {/*  {message.attachments &&*/}
        {/*    <div className="space space-center-y">*/}
        {/*      <AttachmentIcon className="muted-icon" />*/}
        {/*      <Typography.Text className="font-xl muted">{Object.keys(message.attachments).length}</Typography.Text>*/}
        {/*    </div>}*/}
        {/*</div>*/}
      {/*</div>*/}
    </div>
  </Card>
}

export default MessageCard;
