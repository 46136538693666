import React from "react";
import PropTypes from "prop-types";
import {PhoneCallIcon, XIcon} from "@web/assets/icons";
import voiceActions from "@web/redux/voice/actions";
import {useDispatch, useSelector} from "react-redux";
import {Button, notification, Tag, Tooltip} from "antd";
import VoiceMuteButton from "./VoiceMuteButton";
import VoiceRecordButton from "./VoiceRecordButton";

VoiceCallButton.propTypes = {
  to: PropTypes.string.isRequired,
  // name: PropTypes.string,
  // callUserId: PropTypes.string,
  // callSid: PropTypes.string,
};

function VoiceCallButton(props) {
  const dispatch = useDispatch();
  // const callStatus = useSelector((state) => {
  //   return state.Voice[props.callSid]?.status;
  // });
  const callDirection = useSelector((state) => {
    return state[props.callSid]?.direction;
  });
  const registered = useSelector(state => state.Voice.registered);
  // const acceptCall = (callSid) => dispatch(voiceActions.acceptCall(callSid));
  const connectCall = (params) => dispatch(voiceActions.connectCall(params));
  // const disconnectCall = (callSid) => dispatch(voiceActions.disconnectCall(callSid));

  const onClickConnect = () => {
    // if (!registered) {
    //   return notification.warning({
    //     message: 'Voice is not ready',
    //     description: 'Please try again',
    //   });
    // }
    connectCall({To: props.to});
    // console.log('onClickConnect', props.to);
    // callDirection === 'INCOMING' ?
    //   acceptCall(props.callSid) :
    //   connectCall({To: props.to});
  }

  // const onClickEnd = () => {
  //   console.log('onClickEnd', callStatus);
  //   switch (callStatus) {
  //     case 'pending':
  //     case 'ringing':
  //     case 'connecting':
  //     case 'open':
  //     case 'connected':
  //       return disconnectCall(props.callSid);
  //     default:
  //       return disconnectCall(props.callSid);
  //   }
  // }

  const disableCallButton = () => {
    if (!registered) {
      return true;
    }
    // switch (callStatus) {
    //   case 'connecting':
    //   case 'connected':
    //   case 'disconnecting':
    //   case 'open':
    //     return true;
    //   default:
    //     return false;
    // }
  }

  // const disableEndButton = () => {
  //   switch (true) {
  //     case !registered:
  //     // case callStatus === 'pending':
  //     case callStatus === 'disconnected':
  //     case callStatus === 'disconnecting':
  //       return true;
  //     default:
  //       return false;
  //   }
  // }

  // const activeCall = () => {
  //   switch (callStatus) {
  //     case 'connecting':
  //     case 'connected':
  //     case 'disconnecting':
  //     case 'ringing':
  //     case 'open':
  //       return true;
  //     default:
  //       return false;
  //   }
  // }

  // const connectButton = () =>
  //   <Tooltip title="Call" trigger={['hover']} placement="bottom">
  //     <Button
  //       ghost
  //       disabled={disableCallButton()}
  //       onClick={onClickConnect}
  //       size="large"
  //       shape="circle"
  //     >
  //       <PhoneCallIcon
  //         className={activeCall() ? '' : registered ? 'green-icon' : 'muted'}
  //         style={{position: 'relative', top: '2px'}}
  //       />
  //     </Button>
  // </Tooltip>;

  // const endButton = () =>
  //   <Tooltip title="End" trigger={['hover']} placement="bottom">
  //   <Button
  //     danger
  //     ghost
  //     disabled={disableEndButton()}
  //     onClick={onClickEnd}
  //     size="large"
  //     shape="circle"
  //   >
  //     <XIcon />
  //   </Button>
  // </Tooltip>

  // const getButton = () => {
  //   switch(callStatus) {
  //     case 'pending': return [connectButton(), endButton()];
  //     case 'connecting': return [endButton()];
  //     case 'connected': return [endButton()];
  //     case 'disconnecting': return [endButton()];
  //     case 'ringing': return [endButton()];
  //     case 'disconnected': return [connectButton()];
  //     case 'open': return [endButton()];
  //     default: return [connectButton()];
  //   }
  // }

  // console.log("VoiceCallButton:status:", callStatus);

  return <div className="space space-center-y">
    <Tooltip title="Call" trigger={['hover']} placement="bottom">
      <Button
        ghost
        disabled={disableCallButton()}
        onClick={onClickConnect}
        size="large"
        shape="circle"
      >
        <PhoneCallIcon
          className="green-icon"
          style={{position: 'relative', top: '2px'}}
        />
      </Button>
    </Tooltip>
    {/*{activeCall() && <VoiceMuteButton />}*/}
    {/*{getButton()}*/}
    {/*<Tooltip title={props.to} trigger={['hover']} placement="bottom" key={props.to}>*/}
    {/*  <h6 className={!registered ? 'muted' : 'ai-text' }>{props.name}</h6>*/}
    {/*</Tooltip>*/}
    {/*<VoiceRecordButton />*/}
  </div>
}

export default VoiceCallButton;
