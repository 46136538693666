import React from "react";
import {Button, Carousel, Menu} from "antd";
import backgroundActions from "@web/redux/background/actions";
import {AccountIcon, DownArrowOutlined, LogoutIcon, RightArrowOutlined} from "@web/assets/icons";
import {useDispatch} from "react-redux";
import {Link} from "wouter";
import DashboardRow from "../DashboardRow";
import {
  Welcome,
  Team,
  Origin,
  Connect,
  LinkedAccounts,
  // Invites,
  Websites,
  Voice,
  Training,
  Features,
  Terms,
  Summary, Tools,
} from "./Onboarding";

import PropTypes from "prop-types";
import {useAuthOven} from "@web/hooks/useAuthOven";
import {auth} from "@web/lib/firebase";

OnboardingRouter.propTypes = {
  initialStepIndex: PropTypes.number.isRequired,
  stepNames: PropTypes.array.isRequired,
  completedNames: PropTypes.array.isRequired,
  isMobile: PropTypes.bool.isRequired,
  onCompleteStep: PropTypes.func.isRequired,
};

export default function OnboardingRouter(props) {
  const dispatch = useDispatch();
  const slider = React.useRef({});
  const [user, claims, loadingUser] =
    useAuthOven(auth);

  const [disableNavigation, setDisableNavigation] =
    React.useState(false);

  const [currentIndex, setCurrentIndex] =
    React.useState(props.initialStepIndex);

  const startAnimation =
    React.useCallback(() =>
      dispatch(backgroundActions.startAnimation()), [dispatch]);

  const pauseAnimation =
    React.useCallback(() =>
      dispatch(backgroundActions.pauseAnimation()), [dispatch]);

  const navigateNext =
    React.useCallback(() => {
      slider.current?.next()
    }, []);

  const markCompleteAndNavigateNext =
    React.useCallback((stepName) => {
      props.onCompleteStep(stepName);
      navigateNext();
    }, []);

  if (!user) {
    return null;
  }

  const boards = {
    welcome: Welcome,
    origin: Origin,
    connect: Connect,
    tools: Tools,
    // linkedAccounts: LinkedAccounts,
    websites: Websites,
    voice: Voice,
    features: Features,
    terms: Terms,
    // invites: Invites,
    training: Training,
    summary: Summary,
    team: Team,
  }

  return <section style={{position: 'relative'}}>
    <Carousel
      ref={(ref) => slider.current = ref}
      initialSlide={props.initialStepIndex}
      className="splash-carousel splash-carousel-dots"
      adaptiveHeight
      prevArrow={null}
      nextArrow={
      !props.isMobile &&
        !disableNavigation &&
        <Button icon={<RightArrowOutlined />} />
      }
      arrows={false}
      swipe={!disableNavigation}
      dots={!disableNavigation}
      accessibility={!props.isMobile && !disableNavigation}
      // beforeChange={() => console.log('beforeChange')}
      // afterChange={(index) => setCurrentIndex(index)}
      onSwipe={() => null}
      lazyLoad="ondemand"
      infinite={false}
      draggable={false}
      waitForAnimate={true}
    >
      {props.stepNames.map((stepName, index) => {
        return React.createElement(boards[stepName], {
          key: stepName,
          name: stepName,
          setDisableNavigation,
          pauseAnimation,
          startAnimation,
          navigateNext,
          markCompleteAndNavigateNext,
        });
      })}
    </Carousel>
    <div style={{position: "fixed", top: "20px", right: "20px", width: '100%'}}>
      <DashboardRow>
        <div className="space space-center-y space-end">
          <Menu mode="horizontal" theme="dark" className="splash-main-menu">
            <Menu.SubMenu
              key="SubMenu"
              title={<span>{user?.displayName || user?.email?.split("@")?.[0]}</span>}
              icon={<DownArrowOutlined size={22}/>}
            >
              {/*<Menu.Item key="videos" className="space space-center-y" icon={<CaptureIcon size={18} />}>*/}
              {/*  Videos*/}
              {/*</Menu.Item>*/}
              <Menu.Divider/>
              {user.isAnonymous ?
                <Menu.Item key="login" className="space space-center-y" icon={<AccountIcon size={16}/>}>
                  <Link to="/login">Login</Link>
                </Menu.Item> :
                <Menu.Item key="logout" className="space space-center-y" icon={<LogoutIcon size={16}/>}>
                  <Link to="/logout">Logout</Link>
                </Menu.Item>}
              {/*<Menu.ItemGroup title="Item Group">*/}
              {/*  <Menu.Item key="four" icon={<AppstoreOutlined />}>*/}
              {/*    Navigation Four*/}
              {/*  </Menu.Item>*/}
              {/*  <Menu.Item key="five" icon={<AppstoreOutlined />}>*/}
              {/*    Navigation Five*/}
              {/*  </Menu.Item>*/}
              {/*</Menu.ItemGroup>*/}
            </Menu.SubMenu>
          </Menu>
        </div>
      </DashboardRow>
    </div>
  </section>
}
