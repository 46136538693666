import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";

FileData.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.element,
};

function FileData({component, path, ...props}) {
  const file = useSelector((state) => {
    return state.Content.data[path];
  });

  return React.createElement(
    component,
    {
      ...props,
      path,
      file,
    });
}

export default FileData;
