import React, {useContext} from "react";
import {Redirect} from "wouter";
import {AppContext} from "@web/hooks/context";

const RequireSubscription = ({children}) => {
  const [,user, claims] = useContext(AppContext)
  console.log('RequireSubscription');
  if (!user) return <Redirect to="/sign-up" />
  if (user.isAnonymous) return <Redirect to="/sign-up" />

  return (!!claims.data?.subscribed) ?
    children :
    <Redirect to="/subscribe" />;
}

export default RequireSubscription
