import React from 'react';
import PropTypes from 'prop-types';
import DetailItem from "./timeline/DetailItem";
import {List} from "antd";
import {useSelector} from "react-redux";

DetailItemList.propTypes = {
  contextPath: PropTypes.string,
  // list: PropTypes.arrayOf(PropTypes.array),
};

function DetailItemList(props) {
  const itemsList = useSelector((state) => {
    return state.Content?.activity?.[props.contextPath]?.itemsList;
  });

  return <List
    className="list-item-no-divider"
    dataSource={itemsList}
    renderItem={([itemPath, date], index) => {
      return <List.Item
        id={itemPath}
        key={itemPath + index}
      >
        <DetailItem
          contextPath={props.contextPath}
          path={itemPath}
          date={date}
          primary={false}
          collapsed
        />
      </List.Item>
    }}
  />
}

export default DetailItemList;
