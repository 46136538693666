/** @namespace app */
import "./App.less";
import React, {lazy, Suspense} from "react";
import FeedLayout from "./FeedLayout";
import {Route, Switch, useLocation} from "wouter";
import Login from "@ai-antd/page/Login";
import SignUp from "@ai-antd/page/SignUp";
import {useAuthOven} from "@web/hooks/useAuthOven";
import {auth} from "@web/lib/firebase";
import {AppContext} from "@web/hooks/context";
import Loading from "@ai-antd/components/Loading";
import {RequireAuth} from "@web/ui/containers/RequireAuth";
import OauthCallback from "@ai-antd/page/OauthCallback";
import RequireSubscription from "@web/ui/containers/RequireSubscription";
import BetaCountdown from "@ai-antd/page/BetaCountdown";
import VerifyEmailLink from "@ai-antd/page/VerifyEmailLink";
import {Alert} from "antd";
import TrelloAuthCallback from "@ai-antd/components/TrelloAuthCallback";
import VideoModal from "@ai-antd/components/VideoModal";
import Logout from "@ai-antd/page/Logout";
import HomeWrapper from "@ai-antd/page/HomeWrapper";
import RequireVerification from "@web/ui/containers/RequireVerification";
// import OnboardingRouter from "@ai-antd/components/onboarding/OnboardingRouter";
import OnboardingData from "@ai-antd/components/onboarding/OnboardingData";

const appKey = process.env.REACT_APP_OVEN_APP_ID;
const configKey = appKey?.replace(/-/g, "_");
const About = lazy(() => import("@ai-antd/page/About"));
const Account = lazy(() => import("@ai-antd/page/Account"));
const Dashboard = lazy(() => import("@ai-antd/page/Dashboard"));
const MemberBenefits = lazy(() => import("@ai-antd/components/GenesisMemberBenefits"));
const Splash = lazy(() => import("@ai-antd/components/Splash"));

const App = () => {
  /** @type {useAuthOvenData} */
  const [
    user,
    claims,
    loadingUser,
    errorLoadingUser,
  ] = useAuthOven(auth);

  const [, setLocation] = useLocation();

  const navigateTo = React
  .useCallback((path) =>
    setLocation(path), [setLocation]);

  if (loadingUser) {
    return <Loading/>;
  }

  if (errorLoadingUser) {
    return <Alert
      type="warning"
      banner
      message="Please refresh or try again later"
      description="TheScribe is currently unavailable."
    />;
  }

  if (!claims?.data) {
    return <Loading/>;
  }
  const appContextData = [{root: {key: appKey, configKey}, navigateTo}, user, claims, []];

  return <AppContext.Provider value={appContextData}>
    <FeedLayout>
      <Switch>
        <Route path="/">
          <HomeWrapper/>
        </Route>
        <Route path="/onboarding">
          <RequireAuth allowAnonymous={false}>
            <Suspense fallback={<Loading/>}>
              <OnboardingData />
            </Suspense>
          </RequireAuth>
        </Route>
        {/*<Route path="/chat-demo" key="logout">*/}
        {/*  <Suspense fallback={<Loading />}>*/}
        {/*    <ChatDemoManager>*/}
        {/*      <ChatDemo />*/}
        {/*    </ChatDemoManager>*/}
        {/*  </Suspense>*/}
        {/*</Route>*/}
        <Route path="/logout" key="logout">
          <Logout/>
        </Route>
        <Route path="/genesis">
          <Suspense fallback={<Loading/>}>
            <Splash/>
          </Suspense>
        </Route>
        <Route path="/verify">
          <RequireAuth allowAnonymous={false}>
            <Suspense fallback={<Loading/>}>
              <Splash initialSlideIndex={2}/>
            </Suspense>
          </RequireAuth>
        </Route>
        <Route path="/subscribe">
          <RequireAuth allowAnonymous={false}>
            <RequireVerification>
              <Suspense fallback={<Loading/>}>
                <Splash initialSlideIndex={3}/>
              </Suspense>
            </RequireVerification>
          </RequireAuth>
        </Route>
        <Route path="/genesis-access">
          <RequireAuth allowAnonymous={false}>
            <Suspense fallback={<Loading/>}>
              <MemberBenefits/>
            </Suspense>
          </RequireAuth>
        </Route>
        <Route path="/genesis-start">
          <RequireAuth allowAnonymous={false}>
            <Suspense fallback={<Loading/>}>
              <Splash initialSlideIndex={3}/>
            </Suspense>
          </RequireAuth>
        </Route>
        <Route path="/subscribed" key="subscribed">
          <RequireAuth allowAnonymous={false}>
            <RequireVerification>
              <Suspense fallback={<Loading/>}>
                <BetaCountdown/>
              </Suspense>
            </RequireVerification>
          </RequireAuth>
        </Route>
        <Route path="/today" key="today">
          <RequireAuth allowAnonymous={false}>
            <RequireVerification>
              <RequireSubscription>
                <Suspense fallback={<Loading/>}>
                  <Dashboard/>
                </Suspense>
              </RequireSubscription>
            </RequireVerification>
          </RequireAuth>
        </Route>
        <Route path="/email-link">
          <VerifyEmailLink/>
        </Route>
        <Route path="/oauth/:integrationId/callback" key="oauth-callback">
          {({integrationId}) => integrationId === "trello-com" ? <TrelloAuthCallback integrationId={integrationId}/> :
            <OauthCallback integrationId={integrationId}/>}
        </Route>
        <Route path="/login" key="login">
          <Login
            providers={["email"]}
          />
        </Route>
        <Route path="/sign-up" key="sign-up">
          <SignUp providers={["email"]}/>
        </Route>
        <Route path="/account" key="account">
          <Suspense fallback={<Loading/>}>
            <Account/>
          </Suspense>
        </Route>
        <Route path="/get-started" key="get-started">
          <Suspense fallback={<Loading/>}>
            <Account onboarding/>
          </Suspense>
        </Route>
        <Route path="/about" key="about">
          <Suspense fallback={<Loading/>}>
            <About/>
          </Suspense>
        </Route>
        {/*<Route path="/io">*/}
        {/*  <Suspense fallback={<Loading />}>*/}
        {/*    <Io />*/}
        {/*  </Suspense>*/}
        {/*</Route>*/}
      </Switch>
      <VideoModal/>
    </FeedLayout>
  </AppContext.Provider>;
};
export default App;
