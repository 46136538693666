import React, {useState} from "react";
import {Button, Popover, Space, message} from "antd";
import {DeleteOutlined, DislikeFilled, DislikeOutlined, LikeFilled, LikeOutlined} from '@ant-design/icons'
import {addPath, deletePath, updatePath} from "@web/lib/firestore.db";
import {SayButton} from "react-say"
import {AudioIcon} from "@web/assets/icons";
import {AppContext} from "@web/hooks/context";
import DevButton from "./DevButton";

export default function ContentActions({children, placement, text, like, path, saveAction, rerunAction, agentActions, trigger}) {
  const [, user] = React.useContext(AppContext)
  const [open, setOpen] = useState(false)
  const [speak, setSpeak] = useState(false)

  const onLike = (like) => updatePath(path, {like})
    .then(() => message.info({content: like ? "Liked" : "Disliked", icon: like ? <LikeOutlined /> : <DislikeOutlined />}))
    .then(() => addPath(`user/${user.uid}/reactions`, {
      reactions: like === true ? ["like"] : like === false ? ["dislike"] : null,
      sourcePath: path,
      ts: Date.now(),
    }))
    .catch(() => message.error('Try again later'))

  const onClickDelete = () => {
    return deletePath(path)
      .then(() => message.success('Deleted'))
      .catch(() => message.error('Oops, unable to delete'))
  }

  const handleOpenChange = (open) => setOpen(open)
  // const text = Object.values(content || {})?.[0]?.answers?.[0]?.text;
  return <Popover
    content={
      <div className="space space-between action-strip">
        <Button danger icon={<DeleteOutlined />} onClick={onClickDelete} />
        <Space>
          <Button
            icon={like === false ? <DislikeFilled /> : <DislikeOutlined />}
            onClick={() => onLike(false)}
          />
          <Button
            icon={like === true ? <LikeFilled /> : <LikeOutlined />}
            onClick={() => onLike(true)}
          />
          {saveAction}
          {rerunAction}
          {agentActions}
          {/*<Button ghost onClick={() => setSpeak(true)}><AudioIcon /></Button>*/}
          <span className="apply-ant-btn ghost">
            <SayButton
              className="ant-btn ant-btn-ghost ghost"
              speak={text || "Sorry, something went wrong"}
              onEnd={() => setSpeak(false)}>
                <AudioIcon />
            </SayButton>
          </span>
        </Space>
        <DevButton path={path} loader="" ghost={false} />
      </div>
    }
    title={null}
    trigger={trigger || "contextMenu"}
    open={open}
    onOpenChange={handleOpenChange}
    placement={placement || "top"}
  >
    {children}
  </Popover>
}
